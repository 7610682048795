<template>
  <div id="context" :class="right ? 'ml-md-3' : 'mr-md-3'">
    <div v-if="is_oppenned && is_mobile" @click="close()" class="backdrop animated fadeIn fasted" />
    <b-button class="btn-context" variant="info" pill v-if="is_mobile && !hide_button && !is_keyboard_open"
      @click="is_oppenned = !is_oppenned">
      <b-icon :icon="is_oppenned ? 'x' : 'three-dots'" />
    </b-button>
    <div class="context-menu card py-md-3 px-md-2 p-0 min-h-62" :class="[
      is_mobile && 'mobile shadow',
      is_oppenned && 'oppenned',
      context_class,
    ]">
      <nav-header hide_profile v-if="is_mobile" />
      <slot @click="is_oppenned = false" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { NavHeader } from "@/components";

export default {
  components: {
    NavHeader,
  },
  props: {
    right: {
      type: Boolean,
    },
    hide_button: {
      type: Boolean,
    },
    context_class: {
      type: String,
    },
  },
  data() {
    return {
      is_oppenned: false,
    };
  },
  methods: {
    close() {
      this.is_oppenned = false;
    },
  },
  computed: {
    ...mapGetters(["is_mobile", "is_keyboard_open"]),
  },
};
</script>

<style lang="scss" scoped>
#context {
  .context-menu {
    min-width: 260px;
    max-width: 260px;
    max-height: 500px;
    overflow-y: auto;

    button {
      line-height: 1;
    }

    &.mobile {
      min-width: 300px;
      padding: 1em;
      position: fixed;
      overflow: visible;
      transition: all 0.3s ease-out;
      height: 100%;
      max-height: 100%;
      top: 0;
      left: -100%;
      border-radius: 0;
      z-index: +101;
      border: none;

      .btn-block {
        text-align: left !important;
        padding: 0 2em !important;
      }

      button {
        border-radius: 0 !important;
      }
    }

    &.oppenned {
      left: 0;
    }
  }

  .btn-context {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 50px;
    width: 50px;
    padding: 0;
    line-height: 2.5;
    bottom: 75px;
    left: 15px;
    z-index: 3;
    padding: 0 10px;
  }

  .backdrop {
    position: fixed;
    top: 0;
    z-index: +100;
  }
}
</style>
