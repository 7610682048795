import { api } from "@/services/api";

export default {
  state: {
    marital_status: [],
    gender: [],
  },
  getters: {
    marital_status: (state) => state.marital_status,
    gender: (state) => state.gender,
  },
  mutations: {
    marital_status(state, marital_status) {
      state.marital_status = marital_status;
    },
    gender(state, gender) {
      state.marital_status = gender;
    },
  },
  actions: {
    get_marital_status({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get("/marital-status/", { ...params })
          .then(({ data }) => {
            commit("marital_status");
            resolve(data);
          })
          .catch((err) => {
            commit("marital_status");
            reject(err);
          });
      });
    },

    get_genders({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get("/gender/", { ...params })
          .then(({ data }) => {
            commit("gender");
            resolve(data);
          })
          .catch((err) => {
            commit("gender");
            reject(err);
          });
      });
    },
  },
};
