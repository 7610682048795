import { api } from "@/services/api";
import store from "@/store";

export default {
  state: {
    current_balance_sheet: {},
    balance_sheet: [],
  },
  getters: {
    current_balance_sheet: (state) => state.current_balance_sheet,
    balance_sheet: (state) => state.balance_sheet,
  },
  mutations: {
    current_balance_sheet_data(state, current_balance_sheet) {
      state.current_balance_sheet = current_balance_sheet;
    },
    list_balance_sheet(state, balance_sheet) {
      state.balance_sheet = balance_sheet;
    },
  },
  actions: {
    get_all_balance_sheet({ commit }, params) {
      return new Promise((resolve, reject) => {
        const { is_preview, preview_personify, user } = store.getters;
        let provider;
        if (is_preview) {
          provider = preview_personify.id;
        } else {
          provider = user.enterprise?.id;
        }

        api
          .get(`/provider/${provider}/balance-sheet`, { ...params })
          .then(({ data, status }) => {
            commit("list_balance_sheet");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("list_balance_sheet");
            reject(err);
          });
      });
    },
    edit_accounting_entry({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .post("/accounting-entry-item/", {
            ...params,
          })
          .then(({ data, status }) => {
            commit("current_balance_sheet_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_balance_sheet_data");
            reject(err);
          });
      });
    },
  },
};
