import { api } from "@/services/api";

export default {
  state: {
    step: {},
    steps: [],
  },
  getters: {
    step: (state) => state.step,
    steps: (state) => state.steps,
  },
  mutations: {
    step_data(state, step) {
      state.step = step;
    },
    list_steps(state, steps) {
      state.steps = steps;
    },
  },
  actions: {
    get_inboud_process({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get("/inbound-process/", { ...params })
          .then(({ data }) => {
            commit("list_steps");
            resolve(data);
          })
          .catch((err) => {
            commit("list_steps");
            reject(err);
          });
      });
    },
    get_step_data({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .get(`/register/${id}/`)
          .then(({ data }) => {
            commit("step_data");
            resolve(data);
          })
          .catch((err) => {
            commit("step_data");
            reject(err);
          });
      });
    },
    go_to_previous_step({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .post(`/register/${id}/previous/`)
          .then(({ data, status }) => {
            commit("step_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("step_data");
            reject(err);
          });
      });
    },
  },
};
