import store from "@/store";
import axios from "axios";

export const url = process.env.VUE_APP_API_BASE_URL;

export const axiosInstance = axios.create({
  baseURL: url,
});

axiosInstance.interceptors.request.use(
  function(config) {
    let token = window.localStorage.token;
    const personify = JSON.parse(localStorage.getItem("personify"));

    if (personify?.token) {
      token = personify?.token;
    }

    if (token) {
      config.headers.Authorization = `JWT ${token}`;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

const handleSendReport = (endpoint, err, method, body, params) => {
  let response;
  const status = err?.response?.status ?? 500;
  switch (status) {
    case 500 || 403 /* 403 from bff*/:
      response = "Estamos com problemas em nossos servidores";
      store.dispatch("send_error", { message: response, status });
      store.dispatch("show_toast", {
        message: response,
        type: "error",
      });
      break;

    case 404:
      store.dispatch("send_error", { message: "Não Encontrado", status });
      return;
    default:
      response = JSON.stringify(err);
      store.dispatch("send_error", { message: response, status });
      break;
  }

  let concatParams = "";
  for (let key in params) {
    if (concatParams != "") {
      concatParams += "&";
    }
    concatParams += key + "=" + encodeURIComponent(params[key]);
  }
};

const checkErrors = (response) => {
  if (!response) return;

  const { data, status } = response;

  if (status === 401) {
    store.dispatch("logout");
  }

  if (!status) {
    store.dispatch("show_toast", {
      message: "Algo deu errado na requisição",
      type: "error",
    });
  }
  data?.errors?.map(({ message }) => {
    setTimeout(() => {
      store.dispatch("show_toast", {
        message,
        type: "error",
      });
    }, 50);
  });
};
export const api = {
  userServer: url.replace("http://", ""),
  async get(endpoint, params) {
    try {
      const response = await axiosInstance.get(endpoint, { params });
      return response;
    } catch (err) {
      handleSendReport(endpoint, err, "GET", "", params);
      checkErrors(err.response);
      return err.response;
    }
  },
  async post(endpoint, body) {
    try {
      const response = await axiosInstance.post(endpoint, body);
      return response;
    } catch (err) {
      handleSendReport(endpoint, err, "POST", body);
      checkErrors(err.response);
      return err.response;
    }
  },
  async put(endpoint, body) {
    try {
      const response = await axiosInstance.put(endpoint, body);
      return response;
    } catch (err) {
      handleSendReport(endpoint, err, "PUT", body);
      checkErrors(err.response);
      return err.response;
    }
  },
  async patch(endpoint, body) {
    try {
      const response = await axiosInstance.patch(endpoint, body);
      return response;
    } catch (err) {
      handleSendReport(endpoint, err, "PATCH", body);
      checkErrors(err.response);
      return err.response;
    }
  },
  async delete(endpoint) {
    try {
      const response = await axiosInstance.delete(endpoint);
      return response;
    } catch (err) {
      handleSendReport(endpoint, err, "DELETE");
      checkErrors(err.response);
      return err.response;
    }
  },
};
