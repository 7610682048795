import store from "@/store";
import Vue from "vue";
import VueRouter from "vue-router";
import { authRoutes } from "./authRoutes";
import { homologationRoutes } from "./homologationRoutes";
import { myEnterpriseRoutes } from "./myEnterpriseRoutes";
import { serviceRoutes } from "./serviceRoutes";
import { systemRoutes } from "./systemRoutes";
import { usersRoutes } from "./usersRoutes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "*",
      component: () => import("@/pages/Errors/404"),
    },
    {
      path: "/server-error",
      component: () => import("@/pages/Errors/500"),
    },
    authRoutes,
    {
      path: "/questionaire",
      name: "Questionário",
      component: () => import("@/pages/Quiz"),
      meta: {
        breadcrumbs: {
          title: "Questionário",
          label: "Questionário",
          icon: "house",
          destination: "/questionaire",
        },
        requiresAuth: false,
      },
    },
    {
      path: "/invite-homologation/detail",
      name: "Detalhes",
      component: () => import("@/pages/InviteHomologation/Details"),
      meta: {
        breadcrumbs: {
          title: "Detalhes da homologação",
          label: "Detalhes da homologação",
          icon: "house",
          destination: "/invite-homologation/detail",
        },
        requiresAuth: false,
      },
    },
    {
      path: "/invite-homologation/decline",
      name: "Recusar",
      component: () => import("@/pages/InviteHomologation/Decline"),
      meta: {
        breadcrumbs: {
          title: "Recusar homologação",
          label: "Recusar homologação",
          icon: "house",
          destination: "/invite-homologation/decline",
        },
        requiresAuth: false,
      },
    },

    {
      path: "/",
      redirect: "/main/dashboard",
      component: () => import("@/pages/Main"),
      meta: {
        breadcrumbs: {
          title: "Início",
          label: "Início",
          icon: "house",
          destination: "/main/dashboard",
        },
        requiresAuth: true,
      },
      children: [
        {
          path: "/main/dashboard",
          meta: {
            breadcrumbs: {
              title: "Dashboard",
              label: "Dashboard",
              icon: "kanban",
              destination: "/main/dashboard",
            },
            requiresAuth: true,
          },
          component: () => import("@/pages/Dashboard"),
        },
        {
          path: "/main/notifications",
          meta: {
            breadcrumbs: {
              title: "Notificações",
              label: "Notificações",
              icon: "bell",
              destination: "/main/notifications",
            },
            requiresAuth: true,
          },
          component: () => import("@/pages/Notifications"),
        },
        /** --- Clients borrower routers */
        {
          path: "/borrower/list",
          meta: {
            breadcrumbs: {
              title: "Tomadores",
              label: "Tomadores",
              icon: "person",
              destination: "/borrower/list",
            },
            transition: "fade",
          },
          component: () => import("@/pages/Clients/borrower/"),
          children: [
            {
              path: "/borrower/list",
              meta: {
                breadcrumbs: {
                  title: "Listagem de Tomadores",
                  label: "Listagem de Tomadores",
                  icon: "justify",
                  destination: "/borrower/list",
                },
                transition: "fade",
              },
              component: () => import("@/pages/Clients/borrower/list"),
            },
            {
              path: "/borrower/edit/:id",
              meta: {
                breadcrumbs: {
                  title: "Editar Tomador",
                  label: "Editar",
                  icon: "pencil",
                  destination: "#",
                },
                transition: "fade",
              },
              component: () => import("@/pages/Clients/borrower/form"),
            },
          ],
        },
        /** --- Clients provider routers */
        {
          path: "/provider/list",
          meta: {
            breadcrumbs: {
              title: "Prestadores",
              label: "Prestadores",
              icon: "person",
              destination: "/provider/list",
            },
            transition: "fade",
          },
          component: () => import("@/pages/Clients/provider/"),
          children: [
            {
              path: "/provider/list",
              meta: {
                breadcrumbs: {
                  title: "Listagem de Prestadores",
                  label: "Listagem",
                  icon: "justify",
                  destination: "/provider/list",
                },
                transition: "fade",
              },
              component: () => import("@/pages/Clients/provider/list"),
            },
            {
              path: "/provider/edit/:id",
              meta: {
                breadcrumbs: {
                  title: "Editar Prestador",
                  label: "Editar",
                  icon: "pencil",
                  destination: "#",
                },
                transition: "fade",
              },
              component: () => import("@/pages/Clients/provider/form"),
            },
          ],
        },

        /** --- Inbound process */
        {
          path: "/inbound-process/",
          meta: {
            breadcrumbs: {
              hidden: true,
              title: "Processo de entrada",
              label: "Processo de entrada",
              icon: "person",
              destination: "/profile",
            },
            transition: "fade",
          },
          component: () => import("@/pages/InboundProcess/"),
        },
        /** --- Profile routes */
        {
          path: "/profile",
          name: "Perfil",
          component: () => import("@/pages/Profile"),
          meta: {
            breadcrumbs: {
              hidden: true,
              title: "Perfil do usuário",
              label: "Perfi",
              icon: "person",
              destination: "/profile",
            },
            transition: "fade",
          },
        },
        usersRoutes,
        homologationRoutes,
        serviceRoutes,
        systemRoutes,
        myEnterpriseRoutes,
      ],
    },
  ],
});
router.afterEach(() => {
  router.app.breadcrumbs$.routeChanged(router.currentRoute);
});

router.beforeEach((to, from, next) => {
  const hasPendingInbound =
    store.getters.user?.has_pending_inbound_process ?? false;
  const isLoggedIn = store.getters.isLoggedIn;

  const isQuestionnaireRoute = to.path.includes("/questionaire");
  const isAuthRoute = to.path.includes("/auth");
  const isInviteRoute = to.path.includes("/invite-homologation");

  if (to.path === "/inbound-process/" && !hasPendingInbound) {
    next({ path: "/" });
  } else if (isAuthRoute && isLoggedIn) {
    next({ path: "/" });
  } else if (isQuestionnaireRoute) {
    next();
  } else if (!isLoggedIn && !isAuthRoute && !isInviteRoute) {
    next({ path: "/auth/login" });
  } else {
    next();
  }
});
export { router };
