import { api } from "@/services/api";
import store from "@/store";

export default {
  state: {
    current_benefit: {},
    benefits: [],
  },
  getters: {
    current_benefit: (state) => state.current_benefit,
    benefits: (state) => state.benefits,
  },
  mutations: {
    current_benefit_data(state, current_benefit) {
      state.current_benefit = current_benefit;
    },
    list_benefits(state, benefits) {
      state.benefits = benefits;
    },
  },
  actions: {
    get_all_benefits({ commit }, params) {
      return new Promise((resolve, reject) => {
        const { is_preview, preview_personify, user } = store.getters;

        let provider;
        if (is_preview) {
          provider = preview_personify.id;
        } else {
          provider = user.enterprise?.id;
        }

        api
          .get(`/provider/${provider}/benefit/`, { ...params })
          .then(({ data, status }) => {
            commit("list_benefits");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("list_benefits");
            reject(err);
          });
      });
    },
    get_benefit({ commit }, params) {
      return new Promise((resolve, reject) => {
        const voucher_id = params.id;
        const { is_preview, preview_personify, user } = store.getters;

        let provider;
        if (is_preview) {
          provider = preview_personify.id;
        } else {
          provider = user.enterprise?.id;
        }

        delete params.id;

        api
          .get(`/provider/${provider}/benefit/${voucher_id}/`, {
            ...params,
          })
          .then(({ data }) => {
            commit("current_benefit_data");
            resolve({ data });
          })
          .catch((err) => {
            commit("current_benefit_data");
            reject(err);
          });
      });
    },
    edit_benefit({ commit }, params) {
      return new Promise((resolve, reject) => {
        const voucher_id = params.id;
        const { is_preview, preview_personify, user } = store.getters;

        let provider;
        if (is_preview) {
          provider = preview_personify.id;
        } else {
          provider = user.enterprise?.id;
        }

        api
          .patch(`/provider/${provider}/benefit/${voucher_id}/`, {
            ...params,
          })
          .then(({ data, status }) => {
            commit("current_benefit_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_benefit_data");
            reject(err);
          });
      });
    },
    add_benefit({ commit }, params) {
      return new Promise((resolve, reject) => {
        const { is_preview, preview_personify } = store.getters;

        if (is_preview) {
          params.provider = preview_personify.id;
        } else {
          delete params.id;
        }

        api
          .post(`/provider/${params.provider}/benefit/`, { ...params })
          .then(({ data, status }) => {
            commit("current_benefit_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_benefit_data");
            reject(err);
          });
      });
    },
    delete_benefit({ commit }, voucher_id) {
      return new Promise((resolve, reject) => {
        const { is_preview, preview_personify, user } = store.getters;
        let provider;
        if (is_preview) {
          provider = preview_personify.id;
        } else {
          provider = user.enterprise?.id;
        }

        api
          .delete(`/provider/${provider}/benefit/${voucher_id}/`)
          .then(({ data }) => {
            commit("list_benefits");
            resolve(data);
          })
          .catch((err) => {
            commit("list_benefits");
            reject(err);
          });
      });
    },
  },
};
