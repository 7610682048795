import { api } from "@/services/api";

export default {
  state: {
    indicator: {},
    indicators: [],
  },
  getters: {
    indicator: (state) => state.indicator,
    indicators: (state) => state.indicators,
    getTypeValidation: () => (type) => {
      const types = {
        percentage: "Percentual",
        manual: "Validação Manual",
        money: "Valor Monetário",
        value: "Valor Simples",
        yes_no: "Sim ou Não",
        yes_no_manual: "Sim/Não (Manual)",
        include: "Inclui",
      };

      return types[type];
    },
  },
  mutations: {
    indicator_data: (state, payload) => {
      state.indicator = payload;
    },
    list_indicators(state, indicators) {
      state.indicators = indicators;
    },
  },
  actions: {
    get_all_indicator({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get("/indicator/", { ...params })
          .then(({ data, status }) => {
            commit("list_indicators");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("list_indicators");
            reject(err);
          });
      });
    },
    get_indicator({ commit }, params) {
      return new Promise((resolve, reject) => {
        const indicator_id = params.id;
        delete params.id;
        api
          .get(`/indicator/${indicator_id}/`, { ...params })
          .then(({ data }) => {
            commit("indicator_data");
            resolve(data);
          })
          .catch((err) => {
            commit("indicator_data");
            reject(err);
          });
      });
    },
    edit_indicator({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .patch(`/indicator/${params.id}/`, { ...params })
          .then(({ data }) => {
            commit("indicator_data");
            resolve(data);
          })
          .catch((err) => {
            commit("indicator_data");
            reject(err);
          });
      });
    },
    add_indicator({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .post("/indicator/", { ...params })
          .then(({ data }) => {
            commit("indicator_data");
            resolve(data);
          })
          .catch((err) => {
            commit("indicator_data");
            reject(err);
          });
      });
    },
    delete_indicator({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .delete(`/indicator/${id}/`)
          .then(({ data }) => {
            commit("list_indicators");
            resolve(data);
          })
          .catch((err) => {
            commit("list_indicators");
            reject(err);
          });
      });
    },
  },
};
