import { api } from "@/services/api";
import store from "@/store";

export default {
  state: {
    current_jobs_position: {},
    jobs_positions: [],
  },
  getters: {
    current_jobs_position: (state) => state.current_jobs_position,
    jobs_positions: (state) => state.jobs_positions,
  },
  mutations: {
    current_jobs_position_data(state, current_jobs_position) {
      state.current_jobs_position = current_jobs_position;
    },
    list_jobs_positions(state, jobs_positions) {
      state.jobs_positions = jobs_positions;
    },
  },
  actions: {
    get_all_jobs_position({ commit }, params) {
      return new Promise((resolve, reject) => {
        const { is_preview, preview_personify, user } = store.getters;
        let provider;

        if (is_preview) {
          provider = preview_personify.id;
        } else {
          provider = user.enterprise?.id;
        }

        api
          .get("/job-position/", { provider, ...params })
          .then(({ data, status }) => {
            commit("list_jobs_positions", data);
            resolve({ data, status });
          })
          .catch((err) => {
            commit("list_jobs_positions", err);
            reject(err);
          });
      });
    },
    get_jobs_position({ commit }, params) {
      return new Promise((resolve, reject) => {
        const id = params.id;
        const { is_preview, preview_personify } = store.getters;

        if (is_preview) {
          params.provider = preview_personify.id;
        }
        delete params.id;

        api
          .get(`/job-position/${id}/`, {
            ...params,
          })
          .then(({ data, status }) => {
            commit("current_jobs_position_data", data);
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_jobs_position_data", err);
            reject(err);
          });
      });
    },
    edit_jobs_position({ commit }, params) {
      return new Promise((resolve, reject) => {
        const id = params.id;
        const { is_preview, preview_personify } = store.getters;

        if (is_preview) {
          params.provider = preview_personify.id;
        }

        api
          .patch(`/job-position/${id}/?provider=${params.provider}`, {
            ...params,
          })
          .then(({ data, status }) => {
            commit("current_jobs_position_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_jobs_position_data");
            reject(err);
          });
      });
    },
    add_jobs_position({ commit }, params) {
      return new Promise((resolve, reject) => {
        const { is_preview, preview_personify } = store.getters;

        if (is_preview) {
          params.provider = preview_personify.id;
        } else {
          delete params.id;
        }

        api
          .post("/job-position/", { ...params })
          .then(({ data, status }) => {
            commit("current_jobs_position_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_jobs_position_data");
            reject(err);
          });
      });
    },
    delete_jobs_position({ commit }, params) {
      return new Promise((resolve, reject) => {
        let provider;
        const { is_preview, preview_personify, user } = store.getters;

        if (is_preview) {
          provider = preview_personify.id;
        } else {
          provider = user.enterprise?.id;
        }
        api
          .delete(`/job-position/${params.id}/?provider=${provider}`)
          .then(({ data }) => {
            commit("current_jobs_position_data");
            resolve(data);
          })
          .catch((err) => {
            commit("current_jobs_position_data");
            reject(err);
          });
      });
    },
  },
};
