export default {
  state: {
    menu_layout: "left",
    enable_application_header: true,
  },
  getters: {
    menu_layout: (state) => state.menu_layout,
    enable_application_header: (state) => state.enable_application_header,
  },
  mutations: {
    set_layout: (state, payload) => {
      state.menu_layout = payload;
    },
    set_enable_application_header: (state, payload) => {
      state.enable_application_header = payload;
    },
  },
  actions: {
    set_menu_layout({ commit }, params) {
      commit("set_layout", params);
    },
    hide_application_header({ commit }, params) {
      commit("set_enable_application_header", params);
    },
  },
};
