export const authRoutes = {
  path: "/auth",
  name: "Autenticação",
  redirect: "/auth/:type",
  component: () => import("../pages/Auth"),

  meta: {
    breadcrumbs: {
      title: "Autenticação",
      label: "Autenticação",
      icon: "house",
      destination: "/auth/:type",
    },
    requiresAuth: false,
  },
  children: [
    {
      path: "/auth/:type",
      meta: {
        title: "Autenticação",
      },
      component: () => import("../pages/Auth"),
    },
  ],
};
