import { api } from "@/services/api";

export default {
  state: {
    is_declined: false,
    is_accepted: false,
    status_homolgation: {},
  },
  getters: {
    is_accepted: (state) => state.is_accepted,
    is_declined: (state) => state.is_declined,
  },
  mutations: {
    handle_get_status_notification(state, status_homolgation) {
      state.status_homolgation = status_homolgation;
    },
    handle_accept_homologation(state, is_accepted) {
      state.status_homolgation = is_accepted;
    },
    handle_decline_homologation(state, is_declined) {
      state.status_homolgation = is_declined;
    },
  },
  actions: {
    get_status_notification({ commit }, { key }) {
      return new Promise((resolve, reject) => {
        api
          .get(`/notification/invite/${key}/`)
          .then(({ data }) => {
            commit("handle_get_status_notification", data);
            resolve(data);
          })
          .catch((error) => {
            commit("handle_get_status_notification", error);
            reject(error);
          });
      });
    },
    accept_homologation({ commit }, { key }) {
      return new Promise((resolve, reject) => {
        api
          .post(`/notification/invite/${key}/accept/`)
          .then(({ data }) => {
            commit("handle_accept_homologation", data);
            resolve(data);
          })
          .catch((error) => {
            commit("handle_accept_homologation", error);
            reject(error);
          });
      });
    },
    decline_homologation({ commit }, { key, justification }) {
      return new Promise((resolve, reject) => {
        api
          .post(`/notification/invite/${key}/decline/`, { justification })
          .then(({ data }) => {
            resolve(data);
            commit("handle_decline_homologation", data);
          })
          .catch((error) => {
            commit("handle_decline_homologation", error);
            reject(error);
          });
      });
    },
  },
};
