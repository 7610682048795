<template>
  <div class="bg-light update-page">
    <div>
      <h3 class="text-dark">Chegou a hora de atualizar!</h3>
      <img class="my-4" src="@/assets/images/update.png" alt="" width="70%" />
      <p class="my-4">
        Nosso time está trabalhando incansavelmente para trazer a melhor
        experiência para você!
      </p>
      <b-button variant="primary" block @click="goto_app_store()">
        Atualizar aplicativo
      </b-button>
      <b-button variant="text" block @click="close_app()">
        Atualizar depois
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions(["goto_app_store", "close_app"]),
  },
};
</script>


<style lang="scss">
.update-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0 2em;
}
</style>