import Vue from "vue";

Vue.directive("disable", (el, binding) => {
  if (binding.value) {
    el.style.opacity = "0.3";
    el.style.pointerEvents = "none";
    el.style.userSelect = "none";
    return el.style;
  }
  el.style = {};
  return el.style;
});
