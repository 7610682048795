import { api } from "@/services/api";

export default {
  state: {
    dashboard: [],
  },
  getters: {
    dashboard: (state) => state.dashboard,
  },
  mutations: {
    set_dashboard_data: (state, payload) => {
      state.dashboard = payload;
    },
  },
  actions: {
    get_dashboard({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .get(`/dashboard/`)
          .then(({ data, status }) => {
            commit("set_dashboard_data", data);
            resolve({ data, status });
          })
          .catch((err) => {
            commit("set_dashboard_data", {});
            reject(err);
          });
      });
    },
  },
};
