import { api } from "@/services/api";

export default {
  state: {
    current_question: {},
    questions: [],
  },
  getters: {
    current_question: (state) => state.current_question,
    questions: (state) => state.questions,
  },
  mutations: {
    current_question(state, current_question) {
      state.current_question = current_question;
    },
    list_questions(state, questions) {
      state.questions = questions;
    },
  },
  actions: {
    get_all_questions_type({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get("/question-type/", { ...params })
          .then(({ data, status }) => {
            resolve({data, status});
          })
          .catch((err) => {
            commit("list_questions");
            reject(err);
          });
      });
    },
    add_question_type({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .post("/question-type/", { ...params })
          .then(({ data, status }) => {
            resolve({data, status});
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    edit_question_type({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .patch(`/question-type/${params.id}/`, { ...params })
          .then(({ data, status }) => {
            resolve({data, status});
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    get_question_type({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/question-type/${params.id}/`)
          .then(({ data, status }) => {
            resolve({data, status});
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    delete_question_type({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .delete(`/question-type/${id}/`)
          .then(({ data }) => {
            resolve({data});
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    get_all_questions({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get("/question/", { ...params })
          .then(({ data }) => {
            commit("list_questions");
            resolve(data);
          })
          .catch((err) => {
            commit("list_questions");
            reject(err);
          });
      });
    },
    get_question({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/question/${params.id}/`)
          .then(({ data }) => {
            commit("current_question");
            resolve(data);
          })
          .catch((err) => {
            commit("current_question");
            reject(err);
          });
      });
    },
    edit_question({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .patch(`/question/${params.id}/`, { ...params })
          .then(({ data }) => {
            commit("current_question");
            resolve(data);
          })
          .catch((err) => {
            commit("current_question");
            reject(err);
          });
      });
    },
    add_question({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .post("/question/", { ...params })
          .then(({ data }) => {
            commit("current_question");
            resolve(data);
          })
          .catch((err) => {
            commit("current_question");
            reject(err);
          });
      });
    },
    delete_question({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .delete(`/question/${id}/`)
          .then(({ data }) => {
            commit("list_questions");
            resolve(data);
          })
          .catch((err) => {
            commit("list_questions");
            reject(err);
          });
      });
    },
  },
};
