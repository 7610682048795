const css = {
  boxed: `
    :root {
      --box: 1360px;
    }
    `,
  extend: `
    :root {
      --box: 100%;
      `,
  "boxed-top": `
    :root {
      --box: 1360px;
    }
    `,
  "extend-top": `
    :root {
      --box: 100%;
      `,
};
export default (selectedlayout) => {
  localStorage.setItem("layout", selectedlayout);
  const layout = localStorage.getItem("layout") ?? "boxed";
  const scss = document.createElement("style");
  scss.setAttribute("data-layout", layout);
  scss.type = "text/css";

  if (scss.styleSheet) scss.styleSheet.cssText = css[layout];
  else scss.appendChild(document.createTextNode(css[layout]));
  document.getElementsByTagName("head")[0].appendChild(scss);
  /** Apply layout meta color */
  const layoutDefault = getComputedStyle(document.querySelector(":root"))
    .getPropertyValue("--box")
    .trim();
  const meta = document.createElement("meta");
  meta.setAttribute("data-layout", layout);
  meta.content = layoutDefault;
  document.getElementsByTagName("head")[0].appendChild(meta);

  document.body.classList.add(`${layout}-layout`);

  let previousLayout = layout === "boxed" ? "extend" : "boxed";
  document.body.classList.remove(`${previousLayout}-layout`);
  document
    .querySelectorAll(`[data-layout="${previousLayout}"]`)
    .forEach((data) => {
      data.remove();
    });
};
