<template>
  <b-badge
    v-if="is_preview"
    variant="danger"
    class="py-2 visualization-mode d-flex justify-content-between"
  >
    <span :class="is_mobile && 'text-left'">
      <span :class="is_mobile && 'd-block'">
        Você está em modo visualização como :</span
      >
      <strong class="ml-md-2">
        {{ preview_personify.social_reason }}
      </strong>
    </span>
    <b-button
      @click="handleStopPreview()"
      size="sm"
      variant="text"
      class="m-0 d-flex align-items-center icon-action"
    >
      <b-icon icon="x" class="icon-action" />
    </b-button>
  </b-badge>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    handleStopPreview: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["is_preview", "preview_personify", "is_mobile"]),
  },
};
</script>
