import store from "../../store/";

const css = {
  dark: `
    :root {
      --input-background: #202020;
      --text-color: #c2c2c2;
      --placeholder-color: #6c757d;
      --text-input-color: #6c757d;
      --border-color: #323232;
      --dark: #ffffff;
      --white: #181818;
      --bg: #181818;
      --bg-1: #1c1c1c;
      --bg-2: #1e1e1e;
    }
    `,
  light: `
    :root {
      --input-background: #ffffff;
      --text-color: #36393f;
      --placeholder-color: #c2c2c2;
      --text-input-color: #36393f;
      --border-color: #dae0e5;
      --dark: #111111;
      --white: #ffffff;
      --bg: #f1f5f9;
      --bg-1: #ffffff;
      --bg-2: #f8fafc;
      `,
};
export default (selectedTheme) => {
  // document.querySelector("name=theme-color").remove();
  localStorage.setItem("theme", selectedTheme);
  const theme = localStorage.getItem("theme") ?? "light";
  const scss = document.createElement("style");

  store.dispatch("set_dark_mode", theme === "dark");

  scss.setAttribute("data-theme", theme);
  scss.type = "text/css";

  if (scss.styleSheet) scss.styleSheet.cssText = css[theme];
  else scss.appendChild(document.createTextNode(css[theme]));
  document.getElementsByTagName("head")[0].appendChild(scss);

  /** Apply theme meta color */
  const themeColor = getComputedStyle(document.querySelector(":root"))
    .getPropertyValue("--bg-1")
    .trim();
  const meta = document.createElement("meta");
  meta.setAttribute("data-theme", theme);
  meta.content = themeColor;
  document.getElementsByTagName("head")[0].appendChild(meta);

  document.body.classList.add(`${theme}-theme`);

  let previousTheme = theme === "dark" ? "light" : "dark";
  document.body.classList.remove(`${previousTheme}-theme`);
  document
    .querySelectorAll(`[data-theme="${previousTheme}"]`)
    .forEach((data) => {
      data.remove();
    });
};
