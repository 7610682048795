<template>
  <div>
    <div class="content-top-menu">
      <ul class="top-menu mb-0">
        <template v-for="(menu, key, index) in menus">
          <div :key="index" v-if="isVisible(menu)">
            <router-link
              v-if="menu.single"
              :to="menu.url"
              @click="handleSelectMenu({}, 0)"
            >
              <li
                @click="expanded = key"
                v-if="!menu.hideInMenu"
                :class="key === expanded && 'oppened'"
              >
                <unicon :name="menu.icon" class="mr-1" v-if="menu.icon" />
                <span>{{ menu.title }}</span>

                <ul
                  class="sub-menu shadow content-wrapper"
                  v-if="!!menu.children"
                >
                  <template v-for="(subMenu, i) in menu.children">
                    <router-link
                      :to="subMenu.url"
                      :key="i"
                      v-if="!subMenu.hideInMenu"
                    >
                      <li>
                        <unicon
                          :name="subMenu.icon"
                          class="mr-1"
                          v-if="subMenu.icon"
                        />
                        <span class="when-closed">{{ subMenu.title }}</span>
                      </li>
                    </router-link>
                  </template>
                </ul>
              </li>
            </router-link>
            <li
              v-else
              @click="handleSelectMenu(menu, key)"
              :class="[
                isActive(menu) && 'active',
                key === expanded && 'oppened',
              ]"
            >
              <unicon :name="menu.icon" class="mr-1" v-if="menu.icon" />
              <span>{{ menu.title }}</span>

              <ul
                class="sub-menu shadow content-wrapper"
                v-if="!!menu.children"
              >
                <template v-for="(subMenu, i) in menu.children">
                  <div :key="i" v-if="isVisible(subMenu)">
                    <router-link :to="subMenu.url" v-if="!subMenu.hideInMenu">
                      <li>
                        <unicon
                          :name="subMenu.icon"
                          class="mr-1"
                          v-if="subMenu.icon"
                        />
                        <span class="when-closed">{{ subMenu.title }}</span>
                      </li>
                    </router-link>
                  </div>
                </template>
              </ul>
            </li>
          </div>
        </template>
      </ul>
    </div>
  </div>
</template>
<script>
import { menuData } from "@/constants/menu";
import { mapGetters } from "vuex";
export default {
  name: "TopMenu",
  data() {
    return {
      selectedMenu: {},
      expanded: null,
      menus: menuData,
    };
  },
  computed: {
    ...mapGetters(["user", "is_preview"]),
  },
  methods: {
    isActive(menu) {
      const list = menu.children.flatMap(({ url }) => [url]);
      return list.includes(this.$route.path);
    },
    handleSelectMenu(menu, key) {
      this.selectedMenu = menu;
      this.expanded = key;
    },

    isVisible(menu) {
      const { role } = this.user;
      return menu.visible?.includes(this.is_preview ? "provider" : role);
    },
  },
};
</script>
<style lang="scss">
.header {
  z-index: 200;
  .router-link-exact-active.router-link-active {
    span,
    .unicon {
      color: $primary !important;
    }
    svg {
      fill: $primary !important;
      path {
        fill: $primary !important;
      }
    }
  }
}
.content-top-menu {
  width: 100%;
  background: $bg-1;
  position: sticky;
  top: 50px;
  z-index: 4;
}
.top-sub-menu {
  flex-direction: row;
  background: white;
  svg {
    width: 16px;
  }
}
.top-menu {
  width: 100%;
  max-width: $box;
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: flex;
  position: relative;
  z-index: +100;
  li {
    white-space: nowrap;
    padding: 8px 12px 8px 12px;
    position: relative;
    cursor: pointer;
    font-size: 14px;
    box-sizing: border-box;
    color: $text-color;
    section {
      display: flex;
    }
    svg {
      fill: $text-color;
      width: 20px;
    }
    &.active {
      background-color: $bg-1;
      color: $primary;
      svg {
        fill: $primary;
      }
    }
    &:hover {
      background-color: $bg-1;
      color: $primary;
      svg {
        fill: $primary;
      }
      .sub-menu {
        display: block;
      }
    }
    .sub-menu {
      min-width: 200px;
      display: none;
      left: 0;
      background: $bg-1;
      position: absolute;
      list-style: none;
      padding: 4px;
      animation: fadeIn 0.2s ease-in-out;
      svg {
        fill: $text-color;
      }
      li {
        color: $text-color;
        padding: 8px 12px;
        &:hover {
          background-color: $bg;
          color: $primary;
          svg {
            fill: $primary;
          }
        }
      }
    }
  }
}
</style>
