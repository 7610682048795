import { api } from "@/services/api";
import store from "@/store";

export default {
  state: {
    current_certificates: {},
    certificates: [],
  },
  getters: {
    current_certificates: (state) => state.current_certificates,
    certificates: (state) => state.certificates,
  },
  mutations: {
    current_certificates_data(state, current_certificates) {
      state.current_certificates = current_certificates;
    },
    list_certificates(state, certificates) {
      state.certificates = certificates;
    },
  },
  actions: {
    get_all_documents({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/document-type`, { ...params })
          .then(({ data }) => {
            resolve({ data });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    add_document_type({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .post("/document-type/", { ...params })
          .then(({ data, status }) => {
            resolve({data, status});
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    edit_document_type({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .patch(`/document-type/${params.id}/`, { ...params })
          .then(({ data, status }) => {
            resolve({data, status});
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    get_document_type({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/document-type/${params.id}/`)
          .then(({ data, status }) => {
            resolve({data, status});
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    delete_document_type({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .delete(`/document-type/${id}/`)
          .then(({ data }) => {
            resolve({data});
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    get_all_certificates_files({ commit }, params) {
      return new Promise((resolve, reject) => {
        const { is_preview, preview_personify, user } = store.getters;
        let provider;
        if (is_preview) {
          provider = preview_personify.id;
        } else {
          provider = user.enterprise?.id;
        }

        api
          .get(`/provider/${provider}/certificates`, { ...params })
          .then(({ data }) => {
            commit("list_certificates");
            resolve({ data });
          })
          .catch((err) => {
            commit("list_certificates");
            reject(err);
          });
      });
    },
    get_all_certificates({ commit }, params) {
      return new Promise((resolve, reject) => {

        api
          .get(`/document`, { ...params })
          .then(({ data }) => {
            commit("list_certificates");
            resolve( { data } );
          })
          .catch((err) => {
            commit("list_certificates");
            reject(err);
          });
      });
    },
    add_certificate({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .post("/document/", { ...params })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    edit_certificates_file({ commit }, params) {
      return new Promise((resolve, reject) => {
        const { is_preview, preview_personify, user } = store.getters;
        let provider;
        if (is_preview) {
          provider = preview_personify.id;
        } else {
          provider = user.enterprise?.id;
        }

        api
          .patch(`/provider/${provider}/certificates/${params.id}/`, {
            ...params,
          })
          .then(({ data, status }) => {
            commit("current_certificates_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_certificates_data");
            reject(err);
          });
      });
    },
    edit_certificates({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .patch(`/document/${params.id}/`, {
            ...params,
          })
          .then(({ data, status }) => {
            commit("current_certificates_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_certificates_data");
            reject(err);
          });
      });
    },
    delete_certificate({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .delete(`/document/${id}/`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    import_certificate_compliance(_, params) {
      return new Promise((resolve, reject) => {
        const { is_preview, preview_personify, user } = store.getters;
        let provider;
        if (is_preview) {
          provider = preview_personify.id;
        } else {
          provider = user.enterprise?.id;
        }

        api
          .post(`/provider/${provider}/certificates/${params}/import/`, {
            ...params,
          })
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
