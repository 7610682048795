import { api } from "@/services/api";
import store from "@/store";

export default {
  state: {
    provider_client: {},
    users_provider: [],
  },
  getters: {
    provider_client: (state) => state.provider_client,
    users_provider: (state) => state.users_provider,
  },
  mutations: {
    provider_client_data(state, provider_client) {
      state.provider_client = provider_client;
      state.is_provider = provider_client.role === "provider";
    },
    list_users_provider(state, users_provider) {
      state.users_provider = users_provider;
    },
  },
  actions: {
    get_all_provider_clients({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get("/provider-client/", { ...params })
          .then(({ data, status }) => {
            commit("list_users_provider");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("list_users_provider");
            reject(err);
          });
      });
    },
    get_provider_client({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get(
            `/provider-client/${params?.id || localStorage.getItem("uuid")}/`
          )
          .then(({ data, status }) => {
            commit("provider_client_data", data);
            resolve({ data, status });
          })
          .catch((err) => {
            commit("provider_client_data", {});
            reject(err);
          });
      });
    },
    edit_provider_client({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .patch(`/provider-client/${params.id}/`, { ...params })
          .then(({ data, status }) => {
            commit("provider_client_data", data);
            resolve({ data, status });
          })
          .catch((err) => {
            commit("provider_client_data");
            reject(err);
          });
      });
    },
    add_provider_client({ commit }, params) {
      const { is_preview, preview_personify, user } = store.getters;

      return new Promise((resolve, reject) => {
        if (is_preview) {
          params.provider = preview_personify.id;
        } else {
          params.provider = user?.enterprise?.id;
        }

        api
          .post("/provider-client/", { ...params })
          .then(({ data, status }) => {
            commit("provider_client_data", data);
            resolve({ data, status });
          })
          .catch((err) => {
            commit("provider_client_data");
            reject(err);
          });
      });
    },
    delete_provider_client({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .delete(`/provider-client/${id}/`)
          .then(({ data }) => {
            commit("list_users_provider");
            resolve(data);
          })
          .catch((err) => {
            commit("list_users_provider");
            reject(err);
          });
      });
    },
  },
};
