import { api } from "@/services/api";

export default {
  state: {
    result_validation_general: {},
  },
  getters: {
    result_validation_general: (state) => state.result_validation_general,
  },
  mutations: {
    result_validation_general_data(state, result_validation_general) {
      state.result_validation_general = result_validation_general;
    },
  },
  actions: {
    // borrower
    get_service_validation_result({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get(
            `/service-validation/${params.service_id}/result/${params.provider_id}/`
          )
          .then(({ data, status }) => {
            commit("result_validation_general_data", data);
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // rankings
    get_service_validation_ranking(_, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/service-validation/${params.service_id}/ranking/`)
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // select provider to service
    select_provider_winner(_, params) {
      return new Promise((resolve, reject) => {
        api
          .post(
            `/service-validation/${params.service_id}/winner/${params.provider_id}/`
          )
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    get_fundamentals(_, params) {
      return new Promise((resolve, reject) => {
        api
          .get(
            `/service-validation/${params.service_id}/fundamentals/${params.provider_id}/`
          )
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    get_parts(_, params) {
      return new Promise((resolve, reject) => {
        api
          .get(
            `/service-validation/${params.service_id}/parts/${params.provider_id}/`
          )
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    request_report(_, params) {
      return new Promise((resolve, reject) => {
        api
          .post("/service-report/", params)
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
