import { api } from "@/services/api";
import store from "@/store";

export default {
  state: {
    current_insurance: {},
    insurances: [],
  },
  getters: {
    current_insurance: (state) => state.current_insurance,
    insurances: (state) => state.insurances,
  },
  mutations: {
    current_insurance_data(state, current_insurance) {
      state.current_insurance = current_insurance;
    },
    list_insurances(state, insurances) {
      state.insurances = insurances;
    },
  },
  actions: {
    get_all_insurances({ commit }, params) {
      return new Promise((resolve, reject) => {
        const { is_preview, preview_personify } = store.getters;

        if (is_preview) {
          params = {
            ...params,
            provider: preview_personify.id,
          };
        }

        api
          .get("/insurance/", { ...params })
          .then(({ data, status }) => {
            commit("list_insurances");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("list_insurances");
            reject(err);
          });
      });
    },
    get_insurance({ commit }, params) {
      return new Promise((resolve, reject) => {
        let id;
        const { is_preview, preview_personify } = store.getters;

        if (is_preview) {
          params.provider = preview_personify.id;
        }
        id = params.id;
        delete params.id;

        api
          .get(`/insurance/${id}/`, {
            ...params,
          })
          .then(({ data }) => {
            commit("current_insurance_data");
            resolve({ data });
          })
          .catch((err) => {
            commit("current_insurance_data");
            reject(err);
          });
      });
    },
    edit_insurance({ commit }, params) {
      return new Promise((resolve, reject) => {
        let id;
        const { is_preview, preview_personify } = store.getters;

        if (is_preview) {
          params.provider = preview_personify.id;
        }
        id = params.id;
        delete params.id;

        api
          .patch(`/insurance/${id}/`, {
            ...params,
          })
          .then(({ data, status }) => {
            commit("current_insurance_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_insurance_data");
            reject(err);
          });
      });
    },
    add_insurance({ commit }, params) {
      return new Promise((resolve, reject) => {
        const { is_preview, preview_personify } = store.getters;

        if (is_preview) {
          params.provider = preview_personify.id;
        } else {
          delete params.id;
        }

        api
          .post("/insurance/", { ...params })
          .then(({ data }) => {
            commit("current_insurance_data");
            resolve(data);
          })
          .catch((err) => {
            commit("current_insurance_data");
            reject(err);
          });
      });
    },
    delete_insurance({ commit }, params) {
      return new Promise((resolve, reject) => {
        const id = params.id;
        delete params.id;

        api
          .delete(`/insurance/${id}/`)
          .then(({ data }) => {
            commit("list_insurances");
            resolve(data);
          })
          .catch((err) => {
            commit("list_insurances");
            reject(err);
          });
      });
    },
  },
};
