import { api } from "@/services/api";

export default {
  state: {
    validation_admin_history: [],
  },
  getters: {
    validation_admin_history: (state) => state.validation_admin_history,
  },
  mutations: {
    validation_admin_history_data(state, { history }) {
      state.validation_admin_history = history;
    },
  },
  actions: {
    // admin-validation
    get_service_validation_admin(_, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/service-validation/${params.service_id}/admin/`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    get_service_validation_admin_history({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/admin-validation/validation-history/${params.ranking_id}/`)
          .then(({ data, status }) => {
            resolve({ data, status });
            commit("validation_admin_history_data", data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    get_service_validation_admin_indicator(_, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/admin-validation/validation-indicator/`, params)
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    set_service_validation_admin_indicator(_, params) {
      return new Promise((resolve, reject) => {
        api
          .post(`/admin-validation/validation-indicator/`, params)
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    get_service_validation_admin_note(_, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/admin-validation/update-note/`, params)
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    set_service_validation_admin_note(_, params) {
      return new Promise((resolve, reject) => {
        api
          .post(`/admin-validation/update-note/`, params)
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    get_service_validation_admin_costs(_, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/admin-validation/costs/`, params)
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    update_service_validation_admin_costs(_, params) {
      return new Promise((resolve, reject) => {
        api
          .patch(`/admin-validation/costs/${params.id}/`, params)
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    set_service_validation_admin_costs(_, params) {
      return new Promise((resolve, reject) => {
        api
          .post(`/admin-validation/costs/`, params)
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    delete_service_validation_admin_costs(_, id) {
      return new Promise((resolve, reject) => {
        api
          .delete(`/admin-validation/costs/${id}/`)
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
