import { api } from "@/services/api";
import store from "@/store";

export default {
  state: {
    current_labor_lawsuits: {},
    labor_lawsuits: [],
  },
  getters: {
    current_labor_lawsuits: (state) => state.current_labor_lawsuits,
    labor_lawsuits: (state) => state.labor_lawsuits,
  },
  mutations: {
    current_labor_lawsuits_data(state, current_labor_lawsuits) {
      state.current_labor_lawsuits = current_labor_lawsuits;
    },
    list_labor_lawsuits(state, labor_lawsuits) {
      state.labor_lawsuits = labor_lawsuits;
    },
  },
  actions: {
    get_all_labor_lawsuits({ commit }, params) {
      return new Promise((resolve, reject) => {
        const { is_preview, preview_personify, user } = store.getters;
        let provider;
        if (is_preview) {
          provider = preview_personify.id;
        } else {
          provider = user.enterprise?.id;
        }

        api
          .get(`/provider/${provider}/labor-lawsuits`, { ...params })
          .then(({ data, status }) => {
            commit("list_labor_lawsuits");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("list_labor_lawsuits");
            reject(err);
          });
      });
    },
    get_all_lawsuits(_, params) {
      return new Promise((resolve, reject) => {
        const { is_preview, preview_personify, user } = store.getters;
        let provider;
        if (is_preview) {
          provider = preview_personify.id;
        } else {
          provider = user.enterprise?.id;
        }

        api
          .get(`/lawsuit`, { ...params, provider })
          .then(({ data }) => {
            resolve({ data });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    edit_labor_lawsuits({ commit }, params) {
      return new Promise((resolve, reject) => {
        const { is_preview, preview_personify, user } = store.getters;
        let provider;
        if (is_preview) {
          provider = preview_personify.id;
        } else {
          provider = user.enterprise?.id;
        }

        api
          .patch(`/provider/${provider}/labor-lawsuits/${params.id}/`, {
            ...params,
          })
          .then(({ data, status }) => {
            commit("current_labor_lawsuits_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_labor_lawsuits_data");
            reject(err);
          });
      });
    },
    import_certificate(_, params) {
      return new Promise((resolve, reject) => {
        const { is_preview, preview_personify, user } = store.getters;
        let provider;
        if (is_preview) {
          provider = preview_personify.id;
        } else {
          provider = user.enterprise?.id;
        }

        api
          .post(`/provider/${provider}/labor-lawsuits/${params}/import/`, {
            ...params,
          })
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
