import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import balanceSheet from "./modules/balance-sheet";
import benefits from "./modules/benefits";
import borrower from "./modules/borrower";
import caged from "./modules/caged";
import certificates from "./modules/certificates";
import dashboard from "./modules/dashboard";
import deviceActions from "./modules/device-actions";
import employees from "./modules/employees";
import payments from "./modules/employees-payment";
import errorsResponse from "./modules/errorsResponse";
import formHelpers from "./modules/form-helpers";
import formQuestions from "./modules/form-questions";
import homologation from "./modules/homologation";
import inboundProcess from "./modules/inbound-process";
import indicators from "./modules/indicators";
import { insuraceProvider, insuraces } from "./modules/insurances/";
import inviteHomologation from "./modules/invite-homologation";
import jobsPosition from "./modules/jobs-position";
import laborLawsuits from "./modules/labor-lawsuits";
import layout from "./modules/layout";
import legalPersonnel from "./modules/legal-personnel";
import legalResponsible from "./modules/legal-responsible";
import modules from "./modules/modules";
import notification from "./modules/notification";
import premises from "./modules/premises";
import previewProvider from "./modules/preview-provider";
import profiles from "./modules/profiles";
import provider from "./modules/provider";
import questions from "./modules/questions";
import responsive from "./modules/responsive";
import segments from "./modules/segments";
import serviceScope from "./modules/service-scope";
import {
  adminValidation,
  borrowerValidation,
  providerValidation,
} from "./modules/service-validation/index";
import sesmet from "./modules/sesmet";
import snapshot from "./modules/snapshot";
import systemManagement from "./modules/system-management";
import template from "./modules/template";
import templateIndicators from "./modules/template-indicator";
import theme from "./modules/theme-preferences";
import toaster from "./modules/toaster";
import users from "./modules/users";
import usersProvider from "./modules/users-provider";
import log from "./modules/log";
//vue-multiselect-listbox
import 'vue-multiselect-listbox/dist/vue-multi-select-listbox.css';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  modules: {
    auth,
    errorsResponse,
    users,
    toaster,
    homologation,
    borrower,
    provider,
    inboundProcess,
    segments,
    serviceScope,
    inviteHomologation,
    notification,
    legalResponsible,
    legalPersonnel,
    formHelpers,
    layout,
    responsive,
    theme,
    modules,
    indicators,
    templateIndicators,
    template,
    profiles,
    jobsPosition,
    employees,
    previewProvider,
    insuraces,
    insuraceProvider,
    questions,
    usersProvider,
    formQuestions,
    benefits,
    payments,
    sesmet,
    caged,
    laborLawsuits,
    snapshot,
    systemManagement,
    balanceSheet,
    premises,
    certificates,
    deviceActions,
    dashboard,
    adminValidation,
    borrowerValidation,
    providerValidation,
    log,
  },
});
