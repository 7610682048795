import { api } from "@/services/api";

export default {
  state: {
    unread_notifications: 0,
    notifications: {},
    invite: {},
  },
  getters: {
    notifications: (state) => state.notifications,
    unread_notifications: (state) => state.unread_notifications,
    invite: (state) => state.invite,
  },
  mutations: {
    list_notifications(state, notifications) {
      state.notifications = notifications;
    },
    unread_notifications_data(state, unread_notifications) {
      state.unread_notifications = unread_notifications;
    },
    get_invite(state, invite) {
      state.invite = invite;
    },
  },
  actions: {
    notification_websocket_connection({ state, getters, dispatch }) {
      const notificationUrlWebsocket =
        process.env.VUE_APP_NOTIFICATION_WEBSOCKET;
      const token = getters.token;
      const user = getters.user;

      let socket = null;

      const connectWebSocket = () => {
        socket = new WebSocket(notificationUrlWebsocket, token);

        socket.onopen = () => {
          // console.log("WebSocket connected");
        };

        socket.onmessage = (event) => {
          const data = JSON.parse(event.data);
          const user_id = data[0];
          const ids = data[1];

          const hasNotificated = ids.every((id) =>
            state.notifications.some((notify) => {
              return notify.id === id && !notify.was_answered;
            })
          );

          const isCurrentUser = +user.id === +user_id;

          if (isCurrentUser && !hasNotificated) {
            dispatch("get_all_notifications");
          }
        };

        socket.onclose = () => {
          // console.log("WebSocket connection closed:", event.code, event.reason);

          // Tente reconectar após 5 segundos
          setTimeout(connectWebSocket, 1000);
        };
      };

      connectWebSocket();
    },

    get_all_notifications({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get("/notification/", { ...params })
          .then(({ data, status }) => {
            commit("unread_notifications_data", data.unread);
            commit("list_notifications", data.results);
            resolve({ data, status });
          })
          .catch((err) => {
            commit("list_notifications");
            reject(err);
          });
      });
    },

    set_readed_notification({ commit, dispatch }, id) {
      return new Promise((resolve, reject) => {
        api
          .patch(`/notification/${id}`)
          .then(({ data, status }) => {
            dispatch("get_all_notifications");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("list_notifications");
            reject(err);
          });
      });
    },

    resend_invite({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .post(`/notification/${id}/resend/`)
          .then(({ data }) => {
            commit("get_invite");
            resolve(data);
          })
          .catch((err) => {
            commit("get_invite");
            reject(err);
          });
      });
    },
  },
};
