import { api } from "@/services/api";
import store from "@/store";

export default {
  state: {
    current_employee: {},
    employee: [],
  },
  getters: {
    current_employee: (state) => state.current_employee,
    employee: (state) => state.employee,
  },
  mutations: {
    current_employee_data(state, current_employee) {
      state.current_employee = current_employee;
    },
    list_employee(state, employee) {
      state.employee = employee;
    },
  },
  actions: {
    get_all_employee({ commit }, params) {
      return new Promise((resolve, reject) => {
        const { is_preview, preview_personify, user } = store.getters;
        let provider;
        if (is_preview) {
          provider = preview_personify.id;
        } else {
          provider = user.enterprise?.id;
        }

        api
          .get(`/employee/`, { provider, ...params })
          .then(({ data, status }) => {
            commit("list_employee");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("list_employee");
            reject(err);
          });
      });
    },
    get_employee({ commit }, id) {
      return new Promise((resolve, reject) => {
        const { is_preview, preview_personify, user } = store.getters;
        let provider;
        if (is_preview) {
          provider = preview_personify.id;
        } else {
          provider = user.enterprise?.id;
        }

        api
          .get(`/employee/${id}/`, { provider })
          .then(({ data, status }) => {
            commit("current_employee_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_employee_data");
            reject(err);
          });
      });
    },
    edit_employee({ commit }, params) {
      return new Promise((resolve, reject) => {
        const id = params.id;
        const { is_preview, preview_personify, user } = store.getters;

        let provider;
        if (is_preview) {
          provider = preview_personify.id;
        } else {
          provider = user.enterprise?.id;
        }

        api
          .patch(`/employee/${id}/`, { provider, ...params })
          .then(({ data, status }) => {
            commit("current_employee_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_employee_data");
            reject(err);
          });
      });
    },
    add_employee({ commit }, params) {
      return new Promise((resolve, reject) => {
        const { is_preview, preview_personify, user } = store.getters;

        let provider;
        if (is_preview) {
          provider = preview_personify.id;
        } else {
          provider = user.enterprise?.id;
        }

        api
          .post(`/employee/`, { provider, ...params })
          .then(({ data, status }) => {
            commit("current_employee_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_employee_data");
            reject(err);
          });
      });
    },
    delete_employee({ commit }, id) {
      return new Promise((resolve, reject) => {
        const { is_preview, preview_personify, user } = store.getters;
        let provider;
        if (is_preview) {
          provider = preview_personify.id;
        } else {
          provider = user.enterprise?.id;
        }

        api
          .delete(`/employee/${id}/`, { provider })
          .then(({ data }) => {
            commit("list_employee");
            resolve(data);
          })
          .catch((err) => {
            commit("list_employee");
            reject(err);
          });
      });
    },
  },
};
