import Vue from "vue";
import store from "../../store";

export const hasPermission = () => {
  const user = store.getters["user"];

  return ["admin", "borrower"].includes(user.role);
};

Vue.directive("permission", (el, binding, vnode) => {
  if (binding.value) {
    if (!hasPermission()) {
      removeElement(el, vnode);
    }
  }
});

function removeElement(el, vnode) {
  // replace HTMLElement with comment node
  const comment = document.createComment(" ");
  Object.defineProperty(comment, "setAttribute", {
    value: () => undefined,
  });
  vnode.elm = comment;
  vnode.text = " ";
  vnode.isComment = true;
  vnode.context = undefined;
  vnode.tag = undefined;
  vnode.data.directives = undefined;

  if (vnode.componentInstance) {
    vnode.componentInstance.$el = comment;
  }

  if (el.parentNode) {
    el.parentNode.replaceChild(comment, el);
  }
}
