import { api } from "@/services/api";
import store from "@/store";

export default {
  state: {
    preview_personify: JSON.parse(localStorage.getItem("personify")) || {},
    is_preview: JSON.parse(localStorage.getItem("ispvd")) || false,
  },
  getters: {
    preview_personify: (state) => state.preview_personify,
    is_preview: (state) => state.is_preview,
  },
  mutations: {
    set_preview_personify_data: (state, payload) => {
      const personify = JSON.parse(localStorage.getItem("personify"));
      if (!personify?.id) {
        return;
      }
      if (personify?.token && store.getters.is_preview) {
        state.preview_personify = { ...personify };
        return;
      }

      api.get(`/user/${payload.user_id}/personify`).then((response) => {
        if (response) {
          const { data } = response;
          state.preview_personify = { ...payload, token: data.token };
          localStorage.setItem(
            "personify",
            JSON.stringify(state.preview_personify)
          );
        }
      });
    },

    set_is_preview_view: (state, payload) => {
      state.is_preview = payload;
      if (state.is_preview) {
        document.body.classList.add("preview-mode");
      } else {
        document.body.classList.remove("preview-mode");
      }
    },
  },
  actions: {
    set_preview_personify({ commit }, params) {
      commit("set_preview_personify_data", params);
    },
    set_is_preview({ commit }, params) {
      localStorage.setItem("ispvd", params);
      commit("set_is_preview_view", params);
    },
  },
};
