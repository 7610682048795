<template>
  <cards-list
    :grid="grid"
    :gap="gap"
    :responsive="responsive"
    :class="customClass"
  >
    <div
      class="skeleton mr-md-2"
      :class="rowClass"
      v-for="(sk, index) in Array.from(Array(+rows).keys())"
      :style="`height: ${height};`"
      :key="index"
    />
  </cards-list>
</template>
<script>
export default {
  props: {
    rows: {
      type: [String, Number],
      required: true,
    },
    customClass: {
      type: String,
    },
    rowClass: {
      type: String,
    },
    height: {
      type: String,
      required: true,
    },
    responsive: {
      type: String,
    },
    grid: {
      type: String,
    },
    gap: {
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
.skeleton {
  opacity: 0.3;
  margin-bottom: 5px;
  background-color: $bg-2;
  background-size: 340px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: shine 1s linear infinite;
  border-radius: $radius;
}
.dark-theme {
  .skeleton {
    background: $bg-2;
  }
}
.light-theme {
  .skeleton {
    background: #e5e9ef;
  }
}
@keyframes shine {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
</style>
