<template>
  <div>
    <b-dropdown
      v-if="isVisible"
      v-b-popover.hover.top="'Ferramenta de desenvolvimento'"
      no-caret
      variant="light"
      class="shadown-lg"
      toggle-class="px-2 btn btn-text bg-transparent white"
    >
      <template #button-content>
        <b-icon-tools style="font-size: 21px" class="m-0" />
      </template>
      <div class="p-2">
        <strong>Ferramentas de desenvolvimento</strong>
        <section class="my-3">
          <b-form-checkbox
            class="pl-0 mb-3"
            v-model="enabledMock"
            @change="handleSelectMock"
          >
            Ativar mock
          </b-form-checkbox>

          <b-form-group label="Role" v-if="enabledMock">
            <b-select v-model="role" @change="handleSelectRole">
              <b-select-option value="provider">Prestador</b-select-option>
              <b-select-option value="borrower">Tomador</b-select-option>
              <b-select-option value="admin">Administador</b-select-option>
            </b-select>
          </b-form-group>
        </section>
      </div>
    </b-dropdown>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isVisible: false,
      enabledMock: false,
      role: "provider",
    };
  },

  methods: {
    resetRoute() {
      this.$router.replace("/").finally(() => {
        window.location.reload();
      });
    },

    handleSelectRole(role) {
      localStorage.setItem("role", role);
      this.resetRoute();
    },

    handleSelectMock(mock) {
      localStorage.setItem("mock", mock);
      this.resetRoute();
    },
  },

  mounted() {
    const mock = localStorage.getItem("mock");
    const role = localStorage.getItem("role");
    this.isVisible = process.env.NODE_ENV === "development";

    this.role = role;

    this.enabledMock = mock === "true";
  },
};
</script>
