import { api } from "@/services/api";
import store from "@/store";

export default {
  state: {
    current_provider: {},
    providers: [],
  },
  getters: {
    current_provider: (state) => state.current_provider,
    providers: (state) => state.providers,
  },
  mutations: {
    current_provider_data(state, current_provider) {
      state.current_provider = current_provider;
    },
    list_providers(state, providers) {
      state.providers = providers;
    },
  },
  actions: {
    get_all_provider({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get("/provider/", { ...params })
          .then(({ data, status }) => {
            commit("list_providers");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("list_providers");
            reject(err);
          });
      });
    },
    get_provider({ commit }, params) {
      return new Promise((resolve, reject) => {
        let id;
        const { is_preview, preview_personify } = store.getters;

        if (is_preview) {
          id = preview_personify.id;
        } else {
          id = params.id;
        }
        api
          .get(`/provider/${id}/`)
          .then(({ data, status }) => {
            commit("current_provider_data", data);
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_provider_data");
            reject(err);
          });
      });
    },
    edit_provider({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .patch(`/provider/${params.id}/`, { ...params })
          .then(({ data, status }) => {
            commit("current_provider_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_provider_data");
            reject(err);
          });
      });
    },
    add_provider({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .post("/provider/", { ...params })
          .then(({ data, status }) => {
            commit("current_provider_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_provider_data");
            reject(err);
          });
      });
    },
    invite_provider(_, params) {
      return new Promise((resolve, reject) => {
        api
          .post("/provider/invite/", { ...params })
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    delete_provider({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .delete(`/provider/${id}/`)
          .then(({ data }) => {
            commit("list_providers");
            resolve(data);
          })
          .catch((err) => {
            commit("list_providers");
            reject(err);
          });
      });
    },
  },
};
