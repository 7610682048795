import axios from "axios";

const urlApi = process.env.VUE_APP_API_BASE_URL;
const sendReport = (title, target, message) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${urlApi}/bot/report`, {
        title,
        message,
        target,
      })
      .then(() => resolve())
      .catch(() => reject());
  });
};
export { sendReport };
