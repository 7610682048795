export const homologationRoutes = {
  path: "/homologation/list",
  meta: {
    breadcrumbs: {
      title: "Listagem de Homologações",
      label: "Homologações",
      icon: "clipboard",
      destination: "/homologation/list",
    },
    transition: "fade",
  },
  component: () => import("@/pages/Homologation/"),
  children: [
    {
      path: "/homologation/list",
      meta: {
        breadcrumbs: {
          title: "Listagem de Homologações",
          label: "Listagem de Homologações",
          icon: "clipboard-minus",
          destination: "/homologation/list",
        },
        transition: "fade",
      },
      component: () => import("@/pages/Homologation/list"),
    },
    {
      path: "/homologation/add",
      meta: {
        breadcrumbs: {
          title: "Adicionar homologação",
          label: "Adicionar",
          icon: "clipboard-plus",
          destination: "#",
        },
        transition: "fade",
      },
      component: () => import("@/pages/Homologation/form"),
    },
  ],
};
