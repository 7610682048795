<template>
  <div>
    <nprogress-container />
    <vue-page-transition name="fade-in-right">
      <UpdateAvaliable v-if="update_avaliable" />
      <div v-else>
        <Loading />
        <toaster />
        <transition :name="$route.transition">
          <router-view />
        </transition>
      </div>
    </vue-page-transition>
  </div>
</template>

<script>
import Toaster from "@/components/Toaster";
import { mapActions, mapGetters } from "vuex";
import Loading from "@/components/Loading";
import UpdateAvaliable from "@/components/UpdateAvaliable";
import NprogressContainer from "vue-nprogress/src/NprogressContainer";
import { SplashScreen } from "@capacitor/splash-screen";

export default {
  name: "App",
  components: { Toaster, Loading, NprogressContainer, UpdateAvaliable },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "has_pending_inbound_process",
      "user",
      "is_provider",
      "error_status",
      "is_native_platform",
      "update_avaliable",
    ]),
  },
  methods: {
    ...mapActions([
      "get_user",
      "set_responsive",
      "toggle_provider",
      "set_preview_personify",
      "set_is_preview",
      "initialize_device_customers",
    ]),
    onResize() {
      this.set_responsive(window.innerWidth < 981);
    },
  },

  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },

  beforeMount() {
    const id = localStorage.getItem("uuid");
    if (id) {
      this.get_user({ id }).then((data) => {
        if (data.has_pending_inbound_process) {
          this.$router.push("/inbound-process/");
        }
      });
    }
    SplashScreen.hide({
      fadeOutDuration: 500,
    });
  },

  async mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });

    this.initialize_device_customers();
  },

  watch: {
    is_provider: {
      handler() {
        const personify = localStorage.getItem("personify");

        if (personify) {
          this.toggle_provider(personify?.length > 10);
          this.set_is_preview(personify?.length > 10);
          this.set_preview_personify(JSON.parse(personify));
        }
      },
      immediate: true,
    },
  },
};
</script>
