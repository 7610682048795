<template>
  <div class="header w-100">
    <frame-preview :handleStopPreview="handleStopPreview" />
    <b-navbar
      style="min-height:50px"
      :variant="is_dark ? 'dark' : 'white'"
      type="dark"
      class="py-0"
      :class="is_inbound && 'justify-content-between'"
    >
      <b-navbar-brand :to="is_inbound ? '' : '/'" class="branding">
        <img
          class="enterprise-image"
          :src="user.enterprise.logo.file"
          :alt="user.enterprise.logo.name"
          height="25"
          v-if="user && user.enterprise && user.enterprise.logo"
        />

        <template v-else>
          <Logo :height="is_mobile ? '35' : '40'" />
        </template>
        <small v-if="is_inbound" class="ml-3 text-muted i"
          >Processo de entrada</small
        >
      </b-navbar-brand>
      <!-- <top-search v-if="!is_inbound" /> -->
      <b-collapse
        is-nav
        class="w-100 d-flex align-items-center"
        v-if="!is_inbound"
      >
        <b-navbar-nav class="ml-auto d-flex align-items-center gap-2">
          <b-nav-form class="w-100 d-none">
            <b-form-input
              size="sm"
              class="ml-2 form-control search-input w-100"
              placeholder="Pesquisar"
            >
            </b-form-input>
          </b-nav-form>
          <development-tools />
          <router-link
            v-if="!hide_profile"
            to="/main/notifications"
            class="px-2 d-flex gap-2 position-relative e"
            v-b-popover.hover.top="'Notificações'"
          >
            <b-icon icon="bell-fill" class="h5 m-0" />
            <span
              v-if="unread_notifications"
              style="color: white !important"
              class="bg-danger position-absolute count-badge"
            >
              {{ unread_notifications }}
            </span>
          </router-link>

          <li v-if="!hide_profile">
            <b-dropdown
              right
              variant="primary"
              toggle-class="drop-menu d-flex align-items-center px-3 radius bordered border-primary"
              class="p-0"
            >
              <template #button-content>
                <span class="mr-4 text-nowrap text-primary d-none d-md-block">
                  {{ user.full_name }}
                </span>
                <avatar
                  height="30px"
                  width="30px"
                  :image="user.photo"
                  :name="
                    user && user.full_name
                      ? user.full_name
                      : user.profile && user.profile.name
                  "
                />
              </template>
              <section class="p-2 my-1">
                <h5 class="mr-2 text-nowrap text-muted">
                  {{
                    user && user.full_name
                      ? user.full_name
                      : user.profile && user.profile.name
                  }}
                </h5>
                <span class="d-block">
                  {{ user.profile && user.profile.name }}
                  ( {{ getRole() }} )
                </span>
                <span class="d-block">
                  <strong> {{ user.email }}</strong>
                </span>
                <hr class="mb-0" />
              </section>
              <b-dropdown-item to="/profile">
                <unicon name="house-user" class="mr-1" />
                Meu perfil
              </b-dropdown-item>
              <b-dropdown-item @click="handleLogout()">
                <unicon name="signout" class="mr-1" /> Sair
              </b-dropdown-item>
            </b-dropdown>
          </li>
        </b-navbar-nav>
      </b-collapse>
      <b-button
        v-if="is_inbound"
        class="pull-right px-4 mb-0"
        variant="light"
        size="sm"
        @click="handleLogout()"
      >
        <span>Sair</span>
      </b-button>
    </b-navbar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Logo from "@/components/Images/Logo";
import FramePreview from "./FramePreview";
import DevelopmentTools from "./DevelopmentTools";
// import TopSearch from "./TopSearch";

export default {
  components: {
    Logo,
    FramePreview,
    DevelopmentTools,
    // TopSearch,
  },
  name: "NavHeader",
  props: {
    is_inbound: {
      type: Boolean,
    },
    hide_profile: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters([
      "user",
      "is_dark",
      "is_preview",
      "preview_personify",
      "is_mobile",
      "unread_notifications",
    ]),
  },
  methods: {
    ...mapActions([
      "logout",
      "toggle_provider",
      "set_is_preview",
      "set_preview_personify",
    ]),
    handleLogout() {
      this.logout().then(() => {
        this.$router.push("/auth/login");
      });
    },
    handleStopPreview() {
      localStorage.removeItem("personify");
      localStorage.removeItem("ispvd");
      this.toggle_provider(false);
      this.set_is_preview(false);
      this.set_preview_personify();
      if (!this.$route.path.split("/").includes("users")) {
        this.$router.push("/users/list");
      }
    },
    getRole() {
      const role = this.user.role;
      if (role === "borrower") {
        return "Tomador";
      }
      if (role === "provider") {
        return "Prestador";
      }
      return "Administador";
    },
  },
  mounted() {
    const theme = localStorage.getItem("theme");
    if (theme === "dark") {
      this.darkTheme = true;
    } else {
      this.darkTheme = false;
    }
  },
};
</script>
<style lang="scss">
.dark-theme {
  .enterprise-image {
    filter: brightness(0) invert(1);
  }
}
.header {
  position: sticky;
  top: 0;
  z-index: +6;

  .visualization-mode {
    border-radius: 0 !important;
  }
  .drop-menu {
    border-radius: $radius;
    &:hover {
      opacity: 0.7;
    }
    &:focus,
    &:active {
      border-color: $text-input-color !important;
    }
  }
  .icon-link {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.branding {
  width: 240px;
}
.search-input {
  background: white !important;
  border-color: transparent;
  min-width: 200px;
  &:focus {
    background: white !important;
  }
}
.count-badge {
  width: 15px;
  height: 15px;
  color: white !important;
  border-radius: 50px;
  text-align: center;
  font-size: 10px;
  top: -5px;
  right: -3px;
  line-height: 1.6;
}
</style>
