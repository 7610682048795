<template>
  <div v-if="!is_keyboard_open">
    <div class="responsive-menu">
      <ul class="d-flex content-menu">
        <router-link
          v-for="(menu, index) in menus"
          :key="index"
          :to="menu.url"
          :id="`menu-${menu.key}`"
          :class="selectedMenu.key === menu.key && 'selected'"
        >
          <unicon :name="menu.icon" />
          <span class="d-block text-muted">{{ menu.title }}</span>
        </router-link>
        <a @click="is_open = !is_open" class="text-center">
          <unicon :name="is_open ? 'multiply' : 'bars'" />
          <span class="d-block text-muted">Menu</span>
        </a>
      </ul>
      <div class="sub-menu pb-4" v-if="is_open">
        <nav-header hide_profile />
        <b-list-group class="w-100">
          <template v-for="(subMenu, i) in subMenus">
            <template v-if="isVisible(subMenu) && !subMenu.children">
              <b-list-group-item
                :key="`menu-${i}`"
                @click="handleCloseMenu(subMenu)"
              >
                <unicon :name="subMenu.icon" v-if="subMenu.icon" />
                <span> {{ subMenu.title }}</span>
              </b-list-group-item>
            </template>
            <!-- With children -->
            <template v-if="isVisible(subMenu) && subMenu.children">
              <b-list-group-item
                :key="`menu-${i}`"
                v-b-toggle="[`template-${i}`]"
                class="d-flex align-items-center justify-content-between"
              >
                <div class="-d-flex align-items-center">
                  <unicon :name="subMenu.icon" v-if="subMenu.icon" />
                  <span> {{ subMenu.title }}</span>
                </div>
                <unicon class="icon-collapse" name="angle-down" />
              </b-list-group-item>
              <div :key="`menu22-${i}`" class="children-menu">
                <b-collapse
                  :id="`template-${i}`"
                  class="mt-2"
                  :key="`sub-menu-${i}-${j}`"
                  v-for="(children, j) in subMenu.children"
                >
                  <div
                    class="pl-4"
                    :key="`sub-menu-${i}-${j}`"
                    v-if="isVisible(children)"
                  >
                    <b-list-group-item
                      @click="handleCloseMenu(children)"
                      class="py-0"
                    >
                      <unicon :name="children.icon" v-if="children.icon" />
                      <span> {{ children.title }}</span>
                    </b-list-group-item>
                  </div>
                </b-collapse>
              </div>
            </template>
          </template>
        </b-list-group>
      </div>
      <div
        @click="is_open = !is_open"
        v-if="is_open"
        class="backdrop animated fadeIn faster"
      />
    </div>
  </div>
</template>

<script>
import { NavHeader } from "@/components";
import { menuData } from "@/constants/menu";
import { mapGetters } from "vuex";

export default {
  components: {
    NavHeader,
  },
  data() {
    return {
      is_open: false,
      hideMenu: false,
      subMenus: menuData,
      selectedMenu: {},
      profiles: [
        { key: 1, value: "admin" },
        { key: 2, value: "borrower" },
        { key: 3, value: "provider" },
        { key: 4, value: "provider" },
      ],
      menus: [
        {
          title: "Home",
          key: "home",
          url: "/main/dashboard",
          icon: "home",
          visible: ["admin", "borrower", "provider"],
        },
        {
          title: "Usuários",
          key: "users",
          url: "/users/list",
          icon: "user",
          visible: ["admin"],
        },
        {
          title: "Empresa",
          key: "informations-enterprise",
          url: "/my-enterprise/list",
          icon: "building",
          visible: ["borrower", "provider"],
        },
        {
          title: "Homologações",
          key: "homologations",
          url: "/homologation/list",
          icon: "package",
          visible: ["admin", "borrower"],
        },
        {
          title: "Serviços",
          key: "services",
          url: "/service/list",
          icon: "constructor",
          visible: ["provider"],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["is_keyboard_open", "user"]),
  },
  methods: {
    handleOpenMenu(menu) {
      this.selectedMenu = menu;
    },

    handleCloseMenu(subMenu) {
      this.is_open = false;
      this.$router.push(subMenu.url);
    },

    isVisible(menu) {
      const { role } = this.user;
      return menu.visible?.includes(this.is_preview ? "provider" : role);
    },

    visiblesMenus() {
      this.menus = this.menus.filter((menu) =>
        menu.visible.includes(this.user.role)
      );
    },
  },

  mounted() {
    this.selectedMenu = this.menus[0];
    this.visiblesMenus();
  },

  watch: {
    user: {
      handler() {
        this.visiblesMenus();
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
.responsive-menu {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  position: fixed;
  z-index: +6;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  width: 100%;

  .content-menu {
    height: 60px;
    bottom: 0;
    left: 0;
    background: $bg-2;
    width: 100%;
    z-index: 0;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: space-around;
    a {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 13px;
    }
    .unicon {
      svg {
        fill: $text-color !important;
        width: 25px;
        height: 25px;
      }
    }
    @media (max-width: $break-md) {
      background: $white;
    }
  }

  .sub-menu {
    min-width: auto;
    width: 80%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: +61;
    position: fixed;
    animation: fadeInLeft 0.2s ease-in-out;
    overflow-y: auto;
    overflow-x: hidden;
  }
  @media (max-width: $break-md) {
    box-shadow: none;
  }
  .children-menu {
    .list-group-item {
      min-height: 45px;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
</style>
