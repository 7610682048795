import { api } from "@/services/api";

export default {
  state: {
    current_profile: {},
    profiles: [],
  },
  getters: {
    current_profile: (state) => state.current_profile,
    profiles: (state) => state.profiles,
  },
  mutations: {
    current_profile_data(state, current_profile) {
      state.current_profile = current_profile;
    },
    list_profiles(state, profiles) {
      state.profiles = profiles;
    },
  },
  actions: {
    get_all_profile({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get("/profile/", { ...params })
          .then(({ data, status }) => {
            commit("list_profiles", data);
            resolve({ data, status });
          })
          .catch((err) => {
            commit("list_profiles");
            reject(err);
          });
      });
    },
    get_profile({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/profile/${params.id}/`, { ...params })
          .then(({ data }) => {
            commit("current_profile_data");
            resolve(data);
          })
          .catch((err) => {
            commit("current_profile_data");
            reject(err);
          });
      });
    },
    edit_profile({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .patch(`/profile/${params.id}/`, { ...params })
          .then(({ data, status }) => {
            commit("current_profile_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_profile_data");
            reject(err);
          });
      });
    },
    add_profile({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .post("/profile/", { ...params })
          .then(({ data, status }) => {
            commit("current_profile_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_profile_data");
            reject(err);
          });
      });
    },
    delete_profile({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .delete(`/profile/${id}/`)
          .then(({ data }) => {
            commit("list_profiles");
            resolve(data);
          })
          .catch((err) => {
            commit("list_profiles");
            reject(err);
          });
      });
    },
  },
};
