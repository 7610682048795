import { router } from "@/routes";
import store from "@/store";
import { FCM } from "@capacitor-community/fcm";
import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
import { LocalNotifications } from "@capacitor/local-notifications";
import { PushNotifications } from "@capacitor/push-notifications";
import { StatusBar } from "@capacitor/status-bar";
import { AppUpdate } from "@capawesome/capacitor-app-update";

export default {
  state: {
    is_native_platform: false,
    is_keyboard_open: false,
    update_avaliable: false,
    token_fcm: "",
  },
  getters: {
    is_native_platform: (state) => state.is_native_platform,
    is_keyboard_open: (state) => state.is_keyboard_open,
    token_fcm: (state) => state.token_fcm,
    update_avaliable: (state) => state.update_avaliable,
  },
  mutations: {
    is_native_platform_data(state, is_native_platform) {
      state.is_native_platform = is_native_platform;
    },
    is_keyboard_open_data(state, is_keyboard_open) {
      state.is_keyboard_open = is_keyboard_open;
    },
    token_fcm_data(state, token_fcm) {
      state.token_fcm = token_fcm;
    },
    update_avaliable_data(state, update_avaliable) {
      state.update_avaliable = update_avaliable;
    },
  },
  actions: {
    async set_native_platform({ commit }) {
      const isNative = Capacitor.isNativePlatform();
      commit("is_native_platform_data", isNative);
      const isDark = store.getters.is_dark ?? false;
      if (isNative) {
        await StatusBar.setBackgroundColor({
          color: isDark ? "#1e1e1e" : "#347cff",
        });
      }
    },

    hide_bottom_menu({ commit }, param) {
      commit("is_keyboard_open_data", param);
    },

    set_keyboard_open({ commit }) {
      const isNative = Capacitor.isNativePlatform();
      if (!isNative) {
        commit("is_keyboard_open_data", false);
        return;
      }

      Keyboard.addListener("keyboardDidShow", () => {
        commit("is_keyboard_open_data", true);
        document.body.classList.add("keyboard-open");
      });

      Keyboard.addListener("keyboardDidHide", () => {
        commit("is_keyboard_open_data", false);
        document.body.classList.remove("keyboard-open");
      });
    },

    async initialize_push_notifications({ commit }) {
      PushNotifications.requestPermissions().then(async () => {
        PushNotifications.register();
        const fcmToken = await FCM.getToken();
        commit("token_fcm_data", fcmToken.token);
      });

      PushNotifications.addListener("registration", (token) => {
        commit("token_fcm_data", token.value);
        //TODO: store token server side?
      });

      await PushNotifications.addListener(
        "pushNotificationReceived",
        (notification) => {
          console.log("Push notification received: ", notification);
        }
      );
    },

    async initialize_local_notifications() {
      LocalNotifications.requestPermissions();
      LocalNotifications.registerActionTypes({
        types: [
          {
            id: "SERVICE_INVITE",
            // actions: [
            //   {
            //     id: "details",
            //     title: "Visualizar detalhes",
            //   },
            //   {
            //     id: "accept",
            //     title: "Aceitar",
            //   },
            //   {
            //     id: "decline",
            //     title: "Recusar",
            //     destructive: true,
            //   },
            // ],
          },
        ],
      });
      LocalNotifications.addListener(
        "localNotificationActionPerformed",
        (res) => {
          if (res.notification.actionTypeId === "SERVICE_INVITE") {
            router.push("/invite-homologation/detail");
          }
        }
      );
    },

    async send_local_notifications() {
      LocalNotifications.schedule({
        notifications: [
          {
            title: "Convite para prestação de serviços",
            summaryText: "teste summary",
            body: "Você foi convidado para uma homologação teste ",
            id: 1,
            extra: {
              data: "Paass your handle user data",
            },
            iconColor: "#347cff",
            actionTypeId: "SERVICE_INVITE",
          },
        ],
      });
    },

    async gotToAppStore() {
      await AppUpdate.openAppStore();
    },

    async goto_app_store() {
      await AppUpdate.openAppStore();
    },

    async close_app() {
      await App.exitApp();
    },

    async verify_updates({ commit }) {
      const isNative = Capacitor.isNativePlatform();
      if (isNative) {
        const result = await AppUpdate.getAppUpdateInfo();
        const isAvaliable = result.immediateUpdateAllowed;
        commit("update_avaliable_data", isAvaliable);
      }
    },

    initialize_device_customers({ dispatch }) {
      const isNative = Capacitor.isNativePlatform();
      if (isNative) {
        dispatch("set_native_platform");
        dispatch("set_keyboard_open");
        dispatch("initialize_push_notifications");
        dispatch("initialize_local_notifications");
        dispatch("verify_updates");
      }
    },
  },
};
