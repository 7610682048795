import { api } from "@/services/api";

export default {
  state: {
    homologation: {},
    homologations: [],
  },
  getters: {
    homologation: (state) => state.homologation,
    homologations: (state) => state.homologations,
  },
  mutations: {
    homologation_data(state, homologation) {
      state.homologation = homologation;
    },
    list_homologations(state, homologations) {
      state.homologations = homologations;
    },
  },
  actions: {
    get_all_homologation({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get("/homologation/", { ...params })
          .then(({ data }) => {
            commit("list_homologations", data);
            resolve(data);
          })
          .catch((err) => {
            commit("list_homologations");
            reject(err);
          });
      });
    },
    get_homologation({ commit }, params) {
      return new Promise((resolve, reject) => {
        const homologation_id = params.id;
        delete params.id;
        api
          .get(`/homologation/${homologation_id}/`, { ...params })
          .then(({ data, status }) => {
            commit("homologation_data", data);
            resolve({ data, status });
          })
          .catch((err) => {
            commit("homologation_data");
            reject(err);
          });
      });
    },
    edit_homologation({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .patch(`/homologation/${params.id}/`, { ...params })
          .then(({ data }) => {
            commit("homologation_data", data);
            resolve(data);
          })
          .catch((err) => {
            commit("homologation_data");
            reject(err);
          });
      });
    },
    add_homologation({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .post("/homologation/", { ...params })
          .then(({ data }) => {
            commit("homologation_data");
            resolve(data);
          })
          .catch((err) => {
            commit("homologation_data");
            reject(err);
          });
      });
    },
    delete_homologation({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .delete(`/homologation/${id}/`)
          .then(({ data }) => {
            commit("list_homologations");
            resolve(data);
          })
          .catch((err) => {
            commit("list_homologations");
            reject(err);
          });
      });
    },
  },
};
