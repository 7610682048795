import { api } from "@/services/api";

export default {
  state: {
    current_borrower: {},
    borrowers: [],
  },
  getters: {
    current_borrower: (state) => state.current_borrower,
    borrowers: (state) => state.borrowers,
  },
  mutations: {
    current_borrower_data(state, current_borrower) {
      state.current_borrower = current_borrower;
    },
    list_borrowers(state, borrowers) {
      state.borrowers = borrowers;
    },
  },
  actions: {
    get_all_borrower({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get("/borrower/", { ...params })
          .then(({ data, status }) => {
            commit("list_borrowers");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("list_borrowers");
            reject(err);
          });
      });
    },
    get_borrower({ commit }, params) {
      return new Promise((resolve, reject) => {
        const id = params?.id;
        delete params.id;
        api
          .get(`/legal-personnel/${id}/`, { ...params })
          .then(({ data, status }) => {
            commit("current_borrower_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_borrower_data");
            reject(err);
          });
      });
    },
    edit_borrower({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .patch(`/borrower/${params.id}/`, { ...params })
          .then(({ data, status }) => {
            commit("current_borrower_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_borrower_data");
            reject(err);
          });
      });
    },
    add_borrower({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .post("/borrower/", { ...params })
          .then(({ data, status }) => {
            commit("current_borrower_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_borrower_data");
            reject(err);
          });
      });
    },
    invite_borrower({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .post("/borrower/invite/", { ...params })
          .then(({ data, status }) => {
            commit("current_borrower_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_borrower_data");
            reject(err);
          });
      });
    },
    delete_borrower({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .delete(`/borrower/${id}/`)
          .then(({ data, status }) => {
            commit("list_borrowers");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("list_borrowers");
            reject(err);
          });
      });
    },
  },
};
