import { api } from "@/services/api";

export default {
  state: {
    current_premises: {},
    current_premise_comment: {},
    premises: [],
    premise_comments: [],
  },
  getters: {
    current_premises: (state) => state.current_premises,
    premises: (state) => state.premises,
    current_premise_comment: (state) => state.current_premise_comment,
    premise_comments: (state) => state.premise_comments,
  },
  mutations: {
    current_premises_data(state, current_premises) {
      state.current_premises = current_premises;
    },
    list_premises(state, premises) {
      state.premises = premises;
    },
    current_premise_comments_data(state, current_premise_comment) {
      state.current_premise_comment = current_premise_comment;
    },
    list_premise_comments(state, premise_comments) {
      state.premise_comments = premise_comments;
    },
  },
  actions: {
    get_all_premises({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/premise/`, { ...params })
          .then(({ data, status }) => {
            commit("list_premises");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("list_premises");
            reject(err);
          });
      });
    },

    get_premise({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .get(`/premise/${id}/`)
          .then(({ data }) => {
            commit("current_premises_data");
            resolve(data);
          })
          .catch((err) => {
            commit("current_premises_data");
            reject(err);
          });
      });
    },
    edit_premises({ commit }, params) {
      return new Promise((resolve, reject) => {
        const id = params.id;

        api
          .patch(`/premise/${id}/`, {
            ...params,
          })
          .then(({ data, status }) => {
            commit("current_premises_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_premises_data");
            reject(err);
          });
      });
    },
    add_premise({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .post(`/premise/`, { ...params })
          .then(({ data, status }) => {
            commit("current_premises_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_premises_data");
            reject(err);
          });
      });
    },
    delete_premises({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .delete(`/premise/${id}/`)
          .then(({ data }) => {
            commit("list_premises");
            resolve(data);
          })
          .catch((err) => {
            commit("list_premises");
            reject(err);
          });
      });
    },

    get_all_premise_comments({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/premise-comment/`, params)
          .then(({ data }) => {
            commit("list_premise_comments");
            resolve(data);
          })
          .catch((err) => {
            commit("list_premise_comments");
            reject(err);
          });
      });
    },
    add_premise_comment({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .post(`/premise-comment/`, {
            ...params,
          })
          .then(({ data, status }) => {
            commit("current_premise_comments_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_premise_comments_data");
            reject(err);
          });
      });
    },
  },
};
