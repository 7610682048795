<template>
  <div class="avatar-container">
    <section v-if="more" :style="`height: ${height}; width: ${width};`">
      <span
        class="initials"
        :class="[noShadow ? '' : 'shadow-sm', bordered ? 'bordered' : '']"
      >
        {{ name }}
      </span>
    </section>
    <section
      v-else-if="image && !more"
      class="section-image-avatar"
      :style="`height: ${height || '30px'}; width: ${width || '30px'};`"
    >
      <img
        :src="image"
        :class="[noShadow ? '' : 'shadow-sm', bordered ? 'bordered' : '']"
      />
    </section>
    <section v-else :style="`height: ${height}; width: ${width};`">
      <span
        class="initials"
        :class="[noShadow ? '' : 'shadow-sm', bordered ? 'bordered' : '']"
      >
        {{ getInitials() }}
      </span>
    </section>
    <div class="online" v-if="is_online" />
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
    },
    height: {
      type: String,
    },
    width: {
      type: String,
    },
    image: {
      type: String,
    },
    gender: {
      type: String,
    },
    more: {
      type: Boolean,
    },
    is_online: {
      type: Boolean,
    },
    bordered: {
      type: Boolean,
    },
    "no-shadow": {
      type: Boolean,
    },
  },
  data() {
    return {
      images: [],
      selectedImage: "",
      background: "",
      color: "",
    };
  },
  methods: {
    importAll(r) {
      r.keys().forEach((key, index) =>
        this.images.push({ index, path: r(key).replace("./", "") })
      );
    },
    getRandomColor() {
      const letters = "0123456789ABCDEF";
      let background = "#";
      for (let i = 0; i < 6; i++) {
        background += letters[Math.floor(Math.random() * 16)];
      }
      this.background = background;
      this.getContrastYIQ(this.background);
    },
    getContrastYIQ(hexcolor) {
      if (hexcolor.slice(0, 1) === "#") {
        hexcolor = hexcolor.slice(1);
      }
      const r = parseInt(hexcolor.substr(0, 2), 16);
      const g = parseInt(hexcolor.substr(2, 2), 16);
      const b = parseInt(hexcolor.substr(4, 2), 16);
      const yiq = (r * 299 + g * 587 + b * 114) / 1000;

      this.color = yiq >= 128 ? "black" : "white";
    },
    getInitials() {
      let name = this.name || "Maat Admin";
      let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

      let initials = [...name.matchAll(rgx)] || [];

      initials = (
        (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
      ).toUpperCase();
      return initials;
    },
  },
  mounted() {
    this.getRandomColor();
  },
};
</script>

<style lang="scss" scoped>
.avatar-container {
  position: relative;
  .section-image-avatar {
    border-radius: 50%;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .initials {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background: $white;
    color: $primary;
    font-size: 13px;

    &.bordered {
      border: 1px solid rgb(193 208 223 / 63%);
    }

    @media (max-width: $break-md) {
      height: 33px;
      width: 33px;
    }
  }
  .online {
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background: $success;
    bottom: -2px;
    right: 0;
  }
}
</style>
