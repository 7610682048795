import { api } from "@/services/api";
import store from "@/store";

export default {
  state: {
    current_legal_personnel: {},
    legal_personnels: [],
  },
  getters: {
    current_legal_personnel: (state) => state.current_legal_personnel,
    legal_personnels: (state) => state.legal_personnels,
  },
  mutations: {
    current_legal_personnel(state, current_legal_personnel) {
      state.current_legal_personnel = current_legal_personnel;
    },
    list_legal_personnels(state, legal_personnels) {
      state.legal_personnels = legal_personnels;
    },
  },
  actions: {
    get_all_legal_personnel({ commit }, params) {
      const { is_preview, preview_personify, user } = store.getters;
      let provider;
      if (is_preview) {
        provider = preview_personify.id;
      } else {
        provider = user?.enterprise?.id;
      }

      return new Promise((resolve, reject) => {
        api
          .get("/legal-personnel/", { ...params, provider })
          .then(({ data, status }) => {
            commit("list_legal_personnels");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("list_legal_personnels");
            reject(err);
          });
      });
    },
    get_legal_personnel({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/legal-personnel/${params.id}/`)
          .then(({ data, status }) => {
            commit("current_legal_personnel");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_legal_personnel");
            reject(err);
          });
      });
    },
    edit_legal_personnel({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .patch(`/legal-personnel/${params.id}/`, { ...params })
          .then(({ data, status }) => {
            commit("current_legal_personnel");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_legal_personnel");
            reject(err);
          });
      });
    },
    add_legal_personnel({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .post("/legal-personnel/", { ...params })
          .then(({ data }) => {
            commit("current_legal_personnel");
            resolve(data);
          })
          .catch((err) => {
            commit("current_legal_personnel");
            reject(err);
          });
      });
    },
    delete_legal_personnel({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .delete(`/legal-personnel/${id}/`)
          .then(({ data }) => {
            commit("list_legal_personnels");
            resolve(data);
          })
          .catch((err) => {
            commit("list_legal_personnels");
            reject(err);
          });
      });
    },
  },
};
