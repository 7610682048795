import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import { register } from "register-service-worker";

const config = {
  apiKey: "AIzaSyBB3CbfnBHU2cTFJdsd9tHrmsObipJjJOI",
  authDomain: "maat-gestao-3bd4d.firebaseapp.com",
  projectId: "maat-gestao-3bd4d",
  storageBucket: "maat-gestao-3bd4d.appspot.com",
  messagingSenderId: "398461326306",
  appId: "1:398461326306:web:fc18d9f1fc36baa9839fbb",
};

initializeApp(config);
let messaging;

const FirebaseMessaging = {
  init() {
    if (!messaging) {
      initializeApp(config);
      messaging = getMessaging();
    }
  },

  initServiceWorker(registration) {
    this.init();
    messaging.useServiceWorker(registration);
    if (registration.active) {
      registration.active.postMessage({
        action: "initializeFirebase",
        payload: { config: config },
      });
    }
  },
};
register(`${process.env.BASE_URL}public/firebase-messaging-sw.js`, {
  ready(registration) {
    FirebaseMessaging.initServiceWorker(registration);
  },
});
