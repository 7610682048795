import { api } from "@/services/api";

export default {
  state: {
    current_form: {},
    current_form_questions: {},
    form: [],
  },
  getters: {
    current_form: (state) => state.current_form,
    current_form_questions: (state) => state.current_form_questions,
    form: (state) => state.form,
  },
  mutations: {
    current_form_data(state, current_form) {
      state.current_form = current_form;
    },
    current_form_questions(state, current_form_questions) {
      state.current_form_questions = current_form_questions;
    },
    list_form(state, form) {
      state.form = form;
    },
  },
  actions: {
    get_all_form({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get("/form/", { ...params })
          .then(({ data, status }) => {
            commit("list_form");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("list_form");
            reject(err);
          });
      });
    },
    get_form({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/form/${params.id}/`)
          .then(({ data, status }) => {
            commit("current_form_data", data);
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_form_data");
            reject(err);
          });
      });
    },
    edit_form({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .patch(`/form/${params.id}/`, { ...params })
          .then(({ data, status }) => {
            commit("current_form_data", data);
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_form_data");
            reject(err);
          });
      });
    },
    add_form({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .post("/form/", { ...params })
          .then(({ data, status }) => {
            commit("current_form_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_form_data");
            reject(err);
          });
      });
    },
    delete_form({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .delete(`/form/${id}/`)
          .then(({ data }) => {
            commit("list_form");
            resolve(data);
          })
          .catch((err) => {
            commit("list_form");
            reject(err);
          });
      });
    },

    edit_form_questions({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .patch(`/form/question/${params.questionId}/`, { ...params })
          .then(({ data, status }) => {
            commit("current_form_questions");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_form_questions");
            reject(err);
          });
      });
    },
    add_form_questions({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .post("/form/question/", { ...params })
          .then(({ data, status }) => {
            commit("current_form_questions");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_form_questions");
            reject(err);
          });
      });
    },
    delete_form_questions({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .delete(`/form/question/${id}/`)
          .then(({ data }) => {
            commit("current_form_questions");
            resolve(data);
          })
          .catch((err) => {
            commit("current_form_questions");
            reject(err);
          });
      });
    },
  },
};
