export default {
  state: {
    is_mobile: false,
  },
  getters: {
    is_mobile: (state) => state.is_mobile,
  },
  mutations: {
    set_is_mobile: (state, payload) => {
      state.is_mobile = payload;
    },
  },
  actions: {
    set_responsive({ commit }, params) {
      commit("set_is_mobile", params);
    },
  },
};
