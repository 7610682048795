export const myEnterpriseRoutes = {
  path: "/my-enterprise/list",
  meta: {
    breadcrumbs: {
      title: "Minha empresa",
      label: "Minha empresa",
      icon: "building",
      destination: "/my-enterprise/list",
    },
    transition: "fade",
  },
  component: () => import("../pages/MyEnterprise/"),
  children: [
    {
      path: "/my-enterprise/list",
      meta: {
        breadcrumbs: {
          title: "Minha Empresa",
          label: "",
          icon: "house",
          destination: "/my-enterprise/list",
        },
      },
      component: () => import("../pages/MyEnterprise/List"),
    },
    {
      path: "/my-enterprise/register/general/",
      meta: {
        breadcrumbs: {
          title: "Meu Cadastro",
          label: "Meu Cadastro",
          icon: "house",
          destination: "/my-enterprise/register/general/",
        },
        transition: "fade",
      },
      component: () => import("../pages/MyEnterprise/Register/"),
      children: [
        {
          path: "/my-enterprise/register/general/",
          meta: {
            breadcrumbs: {
              title: "Minha Empresa - Matriz",
              label: "Matriz",
              icon: "house",
              destination: "/my-enterprise/register/general/",
            },
            transition: "fade",
          },
          component: () => import("../pages/MyEnterprise/Register/general"),
        },
        {
          path: "/my-enterprise/register/branches/",
          meta: {
            breadcrumbs: {
              title: "Minha Empresa - Filiais",
              label: "Filiais",
              icon: "building",
              destination: "/my-enterprise/register/branches/",
            },
            transition: "fade",
          },
          component: () => import("../pages/MyEnterprise/Register/branches/"),
        },
        {
          path: "/my-enterprise/register/legal-responsible/list",
          meta: {
            breadcrumbs: {
              title: "Minha Empresa - Responsáveis legais",
              label: "Responsáveis legais",
              icon: "person",
              destination: "/my-enterprise/register/legal-responsible/list",
            },
            transition: "fade",
          },
          component: () =>
            import("../pages/MyEnterprise/Register/legal-responsible/"),
          children: [
            {
              path: "/my-enterprise/register/legal-responsible/list",
              meta: {
                breadcrumbs: {
                  title: "Minha Empresa - Responsáveis Legais",
                  label: "Listagem",
                  icon: "justify",
                  destination: "/my-enterprise/register/legal-responsible/list",
                },
                transition: "fade",
              },
              component: () =>
                import("../pages/MyEnterprise/Register/legal-responsible/list"),
            },
            {
              path: "/my-enterprise/register/legal-responsible/edit/:id",
              meta: {
                breadcrumbs: {
                  title: "Editar Responsável Legal",
                  label: "Editar",
                  icon: "pencil",
                  destination: "#",
                },
                transition: "fade",
              },
              component: () =>
                import("../pages/MyEnterprise/Register/legal-responsible/form"),
            },
          ],
        },
      ],
    },
    {
      path: "/my-enterprise/financial/income/",
      meta: {
        breadcrumbs: {
          title: "Financeiro",
          label: "Financeiro",
          icon: "cash-stack",
          back: "/my-enterprise/financial/income/",
          destination: "/my-enterprise/financial/income/",
        },
        transition: "fade",
      },
      component: () => import("../pages/MyEnterprise/Financial/"),
    },
    {
      path: "/my-enterprise/legal/labor-lawsuits/list",
      meta: {
        breadcrumbs: {
          title: "Jurídico",
          label: "Jurídico",
          icon: "wallet",
          destination: "/my-enterprise/legal/insurance",
        },
        transition: "fade",
      },
      component: () => import("../pages/MyEnterprise/Legal/"),
      children: [
        {
          path: "/my-enterprise/legal/labor-lawsuits/list",
          meta: {
            breadcrumbs: {
              title: "Jurídico - Certidão de Distribuição de Ações Trabalhistas",
              label: "Certidões",
              icon: "justify",
              destination: "/my-enterprise/legal/labor-lawsuits/list",
            },
            transition: "fade",
          },
          component: () =>
            import("../pages/MyEnterprise/Legal/LaborLawsuits/list"),
        },
        {
          path: "/my-enterprise/legal/insurance/",
          meta: {
            breadcrumbs: {
              title: "Jurídico - Seguros Coberta de Riscos",
              label: "Seguros Coberta de Riscos",
              icon: "shield",
              destination: "/my-enterprise/legal/insurance/",
            },
            transition: "fade",
          },
          component: () => import("../pages/MyEnterprise/Legal/Insurance/list"),
        },
      ],
    },
    {
      path: "/my-enterprise/clients/",
      meta: {
        breadcrumbs: {
          title: "Principais Clientes",
          label: "Principais Clientes",
          icon: "person",
          destination: "/my-enterprise/clients/",
        },
        transition: "fade",
      },
      component: () => import("../pages/MyEnterprise/Clients/list"),
    },
    {
      path: "/my-enterprise/human-resources/jobs-position",
      meta: {
        breadcrumbs: {
          title: "Gestão de pessoas",
          label: "Gestão de pessoas",
          icon: "person-badge",
          destination: "/my-enterprise/human-resources/jobs-position",
        },
        transition: "fade",
      },
      component: () => import("../pages/MyEnterprise/HumanResources"),
      children: [
        {
          path: "/my-enterprise/human-resources/employees/list",
          meta: {
            breadcrumbs: {
              title: "Gestão de pessoas - Gestores Estratégicos",
              label: "Gestores Estratégicos",
              icon: "people",
              destination: "/my-enterprise/human-resources/employees/list",
            },
            transition: "fade",
          },
          component: () =>
            import("../pages/MyEnterprise/HumanResources/Employees/"),

          children: [
            {
              path: "/my-enterprise/human-resources/employees/list",
              meta: {
                breadcrumbs: {
                  title: "Gestão de pessoas - Gestores Estratégicos",
                  label: "Gestores Estratégicos",
                  icon: "justify",
                  destination: "/my-enterprise/human-resources/employees/list",
                },
                transition: "fade",
              },
              component: () =>
                import("../pages/MyEnterprise/HumanResources/Employees/list"),
            },
          ],
        },
        {
          path: "/my-enterprise/human-resources/jobs-position/",
          meta: {
            breadcrumbs: {
              title: "Gestão de pessoas - Organograma",
              label: "Organograma",
              icon: "person-badge-fill",
              destination: "/my-enterprise/human-resources/jobs-position/",
            },
            transition: "fade",
          },
          component: () =>
            import("../pages/MyEnterprise/HumanResources/JobsPosition/"),
        },
        {
          path: "/my-enterprise/human-resources/benefits",
          meta: {
            breadcrumbs: {
              title: "Gestão de pessoas - Benefícios",
              label: "Benefícios",
              icon: "wallet2",
              destination: "/my-enterprise/human-resources/benefits",
            },
            transition: "fade",
          },
          component: () =>
            import("../pages/MyEnterprise/HumanResources/Benefits"),
        },
        {
          path: "/my-enterprise/human-resources/payment",
          meta: {
            breadcrumbs: {
              title: "Gestão de pessoas - Folha de Pagamento",
              label: "Pagamento",
              icon: "cash",
              destination: "/my-enterprise/human-resources/payment",
            },
            transition: "fade",
          },
          component: () =>
            import("../pages/MyEnterprise/HumanResources/Payment"),
        },
        {
          path: "/my-enterprise/human-resources/sesmet",
          meta: {
            breadcrumbs: {
              title: "Gestão de pessoas - Segurança e Medicina do Trabalho",
              label: "Segurança e Med. do Trabalho",
              icon: "droplet-half",
              destination: "/my-enterprise/human-resources/sesmet",
            },
            transition: "fade",
          },
          component: () =>
            import("../pages/MyEnterprise/HumanResources/Sesmet"),
        },
        {
          path: "/my-enterprise/human-resources/caged",
          meta: {
            breadcrumbs: {
              title: "Gestão de pessoas - CAGED",
              label: "CAGED",
              icon: "wallet-fill",
              destination: "/my-enterprise/human-resources/caged",
            },
            transition: "fade",
          },
          component: () => import("../pages/MyEnterprise/HumanResources/Caged"),
        },
      ],
    },
    {
      path: "/my-enterprise/compliance/certificates",
      meta: {
        breadcrumbs: {
          title: "Regularidade",
          label: "Regularidade",
          icon: "book",
          destination: "/my-enterprise/compliance/certificates",
        },
        transition: "fade",
      },
      component: () => import("../pages/MyEnterprise/Compliance/"),
      children: [
        {
          path: "/my-enterprise/compliance/certificates/:document_id/service/:service_id",
          meta: {
            breadcrumbs: {
              title: "Regularidade - Certificados",
              label: "Certificados",
              icon: "book",
              destination: "/my-enterprise/compliance/certificates",
            },
            transition: "fade",
          },
          component: () =>
            import("../pages/MyEnterprise/Compliance/Certificates"),
        },
        {
          path: "/my-enterprise/compliance/certificates-iso",
          meta: {
            breadcrumbs: {
              title: "Regularidade - Certificações ISO",
              label: "Certificações ISO",
              icon: "cpu",
              destination: "/my-enterprise/compliance/certificates-iso",
            },
            transition: "fade",
          },
          component: () =>
            import("../pages/MyEnterprise/Compliance/CertificatesIso"),
        },
      ],
    },
    {
      path: "/my-enterprise/system-management/systems",
      meta: {
        breadcrumbs: {
          title: "Sistemas e Gestão",
          label: "Sistemas e Gestão",
          icon: "cpu",
          destination: "/my-enterprise/system-management/systems",
        },
        transition: "fade",
      },
      component: () => import("../pages/MyEnterprise/SystemManagement/"),
      children: [
        {
          path: "/my-enterprise/system-management/systems",
          meta: {
            breadcrumbs: {
              title: "Sistemas e Gestão - Sistemas",
              label: "Sistemas",
              icon: "cpu",
              destination: "/my-enterprise/system-management/system",
            },
            transition: "fade",
          },
          component: () =>
            import("../pages/MyEnterprise/SystemManagement/Systems"),
        },
        {
          path: "/my-enterprise/system-management/management",
          meta: {
            breadcrumbs: {
              title: "Sistemas e Gestão - Gestão",
              label: "Gestão",
              icon: "cpu",
              destination: "/my-enterprise/system-management/management",
            },
            transition: "fade",
          },
          component: () =>
            import("../pages/MyEnterprise/SystemManagement/Management"),
        },
      ],
    },
  ],
};
