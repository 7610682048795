import { api } from "@/services/api";

export default {
  state: {
    current_legal_responsible: {},
    legal_responsibles: [],
  },
  getters: {
    current_legal_responsible: (state) => state.current_legal_responsible,
    legal_responsibles: (state) => state.legal_responsibles,
  },
  mutations: {
    current_legal_responsible(state, current_legal_responsible) {
      state.current_legal_responsible = current_legal_responsible;
    },
    list_legal_responsibles(state, legal_responsibles) {
      state.legal_responsibles = legal_responsibles;
    },
  },
  actions: {
    get_all_legal_responsible({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get("/legal-responsible/", { ...params })
          .then(({ data, status }) => {
            commit("list_legal_responsibles");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("list_legal_responsibles");
            reject(err);
          });
      });
    },
    get_legal_responsible({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/legal-responsible/${params.id}/`)
          .then(({ data, status }) => {
            commit("current_legal_responsible");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_legal_responsible");
            reject(err);
          });
      });
    },
    edit_legal_responsible({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .patch(`/legal-responsible/${params.id}/`, { ...params })
          .then(({ data, status }) => {
            commit("current_legal_responsible");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_legal_responsible");
            reject(err);
          });
      });
    },
    add_legal_responsible({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .post("/legal-responsible/", { ...params })
          .then(({ data, status }) => {
            commit("current_legal_responsible");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_legal_responsible");
            reject(err);
          });
      });
    },
    delete_legal_responsible({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .delete(`/legal-responsible/${id}/`)
          .then(({ data }) => {
            commit("list_legal_responsibles");
            resolve(data);
          })
          .catch((err) => {
            commit("list_legal_responsibles");
            reject(err);
          });
      });
    },
    invite_legal_responsible({ commit }, invite) {
      return new Promise((resolve, reject) => {
        api
          .post(`/legal-responsible/invite/`, { ...invite })
          .then(({ data, status }) => {
            commit("current_legal_responsible");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_legal_responsible");
            reject(err);
          });
      });
    },
  },
};
