import { api } from "@/services/api";

export default {
  state: {
    service_scope: {},
    service_scopes: [],
    service_provider: [],
  },
  getters: {
    service_scope: (state) => state.service_scope,
    service_scopes: (state) => state.service_scopes,
    service_provider: (state) => state.service_provider,
  },
  mutations: {
    service_scope_data(state, service_scope) {
      state.service_scope = service_scope;
    },
    list_service_scopes(state, service_scopes) {
      state.service_scopes = service_scopes;
    },
    service_provider_data(state, service_provider) {
      state.service_provider = service_provider;
    },
  },
  actions: {
    get_all_service_scope({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get("/service-scope/", { ...params })
          .then(({ data }) => {
            commit("list_service_scopes", data.results);
            resolve(data);
          })
          .catch((err) => {
            commit("list_service_scopes");
            reject(err);
          });
      });
    },
    get_service_scope({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/service-scope/${params.id}/`)
          .then(({ data }) => {
            localStorage.setItem(
              "context" /** [homologation_id, service_id] */,
              JSON.stringify([data.homologation.id, data.id])
            );
            commit("service_scope_data", data);
            resolve(data);
          })
          .catch((err) => {
            commit("service_scope_data");
            reject(err);
          });
      });
    },
    get_service_provider({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/services-by-provider/${params.provider_id}/`)
          .then(({ data }) => {
            commit("service_provider_data", data);
            resolve(data);
          })
          .catch((err) => {
            commit("service_provider_data");
            reject(err);
          });
      });
    },
    edit_service_scope({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .patch(`/service-scope/${params.id}/`, { ...params })
          .then(({ data, status }) => {
            commit("service_scope_data", data);
            resolve({ data, status });
          })
          .catch((err) => {
            commit("service_scope_data");
            reject(err);
          });
      });
    },
    edit_service_document({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .patch(`/service-documents/${params.id}/`, { ...params })
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    add_service_scope({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .post("/service-scope/", { ...params })
          .then(({ data }) => {
            commit("service_scope_data");
            resolve(data);
          })
          .catch((err) => {
            commit("service_scope_data");
            reject(err);
          });
      });
    },
    delete_service_scope({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .delete(`/service-scope/${id}/`)
          .then(({ data }) => {
            commit("list_service_scopes");
            resolve(data);
          })
          .catch((err) => {
            commit("list_service_scopes");
            reject(err);
          });
      });
    },
    finish_service_homologation(_, params) {
      return new Promise((resolve, reject) => {
        api
          .post(`/service-homologation/finish/${params.service_id}/`)
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    start_service_homologation(_, params) {
      return new Promise((resolve, reject) => {
        api
          .patch(`/service-update-status/${params.service_id}/`, { status: 1 })
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    set_empty_service_scope({ commit }, id) {
      commit("service_scope_data", {});
    },
  },
};
