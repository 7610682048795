<template>
  <div
    :class="[
      'cards-list',
      `responsive-${responsive || '1'}`,
      `grid-${grid || '5'}`,
    ]"
    :style="`gap:${gap}px`"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    grid: {
      type: String,
      required: true,
    },
    gap: {
      type: String,
    },
    responsive: {
      type: String,
    },
  },
};
</script>
