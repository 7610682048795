import { api } from "@/services/api";

export default {
  state: {
    user: JSON.parse(localStorage.getItem("user")),
    new_user_data: {},
    users: [],
    has_pending_inbound_process: null,
    is_provider: false,
  },
  getters: {
    user: (state) => state.user,
    new_user_data: (state) => state.new_user_data,
    is_provider: (state) => state.is_provider,
    users: (state) => state.users,
    has_pending_inbound_process: (state) => state.has_pending_inbound_process,
  },
  mutations: {
    pending_inbound(state, user) {
      state.has_pending_inbound_process =
        user && user.id ? user.has_pending_inbound_process : true;
    },
    set_provider(state, provider) {
      state.is_provider = provider ?? false;
    },
    user_data(state, user) {
      state.user = user;
      window.user = user;
      state.is_provider = user.role === "provider";
    },
    new_user_data(state, new_user) {
      state.new_user = new_user;
    },
    list_users(state, users) {
      state.users = users;
    },
  },
  actions: {
    toggle_provider({ commit }, params) {
      commit("set_provider", params);
    },
    update_user({ commit }, payload) {
      commit("user_data", payload);
      commit("pending_inbound", payload);
    },
    get_all_user({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get("/user/", { ...params })
          .then(({ data, status }) => {
            commit("list_users");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("list_users");
            reject(err);
          });
      });
    },
    get_user_by_id(_, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/user/${params?.id || localStorage.getItem("uuid")}/`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    get_user({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/user/${params?.id || localStorage.getItem("uuid")}/`)
          .then(({ data }) => {
            localStorage.setItem("user", JSON.stringify(data));
            commit("user_data", data);
            commit("pending_inbound", data);
            resolve(data);
          })
          .catch((err) => {
            commit("user_data", {});
            commit("pending_inbound", {});
            reject(err);
          });
      });
    },
    edit_user({ commit }, params) {
      return new Promise((resolve, reject) => {
        // parametro user_edit usado para saber quando o usuario esta sendo editado e salvar em log de auditoriat
        api
          .patch(`/user/${params.id}/?user_edit=true`, { ...params })
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            commit("user_data");
            reject(err);
          });
      });
    },
    add_user({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .post("/user/", { ...params })
          .then(({ data, status }) => {
            commit("new_user_data", data);
            resolve({ data, status });
          })
          .catch((err) => {
            commit("new_user_data");
            reject(err);
          });
      });
    },
    delete_user({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .delete(`/user/${id}/`)
          .then(({ data }) => {
            commit("list_users");
            resolve(data);
          })
          .catch((err) => {
            commit("list_users");
            reject(err);
          });
      });
    },
  },
};
