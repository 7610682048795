<template>
  <div>
    <div class="d-flex" v-if="!is_mobile">
      <ul class="side-menu menu mb-0">
        <template v-for="(menu, key, index) in menus">
          <div :key="index" v-if="isVisible(menu)">
            <router-link v-if="menu.single" :to="menu.url">
              <li
                :id="`menu-${menu.key}`"
                :key="index"
                @click="menu.children ? handleSelectMenu(menu, key) : null"
                :class="[menu === selectedMenu && 'oppened', 'gap-2']"
              >
                <unicon :name="menu.icon" v-if="menu.icon" />
                <span v-if="!is_mobile">{{ menu.title }}</span>
              </li>
            </router-link>
            <li
              v-else
              :key="index"
              :id="`menu-${menu.key}`"
              @mouseover="menu.children ? handleSelectMenu(menu, key) : null"
              :class="[
                isActive(menu) && 'router-link-exact-active router-link-active',
                'gap-2',
              ]"
            >
              <unicon :name="menu.icon" v-if="menu.icon" />
              <span v-if="!is_mobile">{{ menu.title }}</span>

              <b-popover
                v-if="selectedMenu.title"
                :target="`menu-${menu.key}`"
                triggers="hover"
                placement="right"
                custom-class="p-0 popover-menu"
              >
                <section class="sub-menu" style="position:relative;left:-5px">
                  <template v-for="(subMenu, i) in selectedMenu.children">
                    <div v-if="isVisible(subMenu)" :key="i">
                      <router-link
                        :to="subMenu.url"
                        custom
                        v-if="!subMenu.hideInMenu"
                      >
                        <li @click="handleHideMenu()" class="py-2">
                          <unicon :name="subMenu.icon" v-if="subMenu.icon" />
                          <span class="when-closed">{{ subMenu.title }}</span>
                        </li>
                      </router-link>
                    </div>
                  </template>
                </section>
              </b-popover>
            </li>
          </div>
        </template>
      </ul>
    </div>
    <menu-responsive v-else />
  </div>
</template>
<script>
import { menuData } from "@/constants/menu";
import { mapGetters } from "vuex";
import MenuResponsive from "./responsive";

export default {
  name: "SideMenu",
  components: {
    MenuResponsive,
  },
  data() {
    return {
      profiles: [
        { key: 1, value: "admin" },
        { key: 2, value: "borrower" },
        { key: 3, value: "provider" },
        { key: 4, value: "borrower" },
      ],
      selectedMenu: {},
      expanded: null,
      menus: menuData,
    };
  },
  computed: {
    ...mapGetters(["is_mobile", "user", "is_preview"]),
  },
  methods: {
    handleSelectMenu(menu, key) {
      this.expanded = key;
      this.selectedMenu = menu;
    },
    isActive(menu) {
      const list = menu.children.flatMap(({ url }) => [url]);
      return list.includes(this.$route.path);
    },
    isVisible(menu) {
      const { role } = this.user;
      return menu.visible?.includes(this.is_preview ? "provider" : role);
    },
    handleHideMenu() {
      this.selectedMenu = {};
    },
  },
};
</script>
<style lang="scss">
.backdrop {
  width: 100%;
  height: 100vh;
  display: block;
  background: rgb(0, 0, 0, 0.6);
  left: 0;
  top: 50px;
  z-index: +46;
}
.menu {
  width: 180px;
  background: $bg-1;
  list-style: none;
  display: block;
  padding: 0;
  position: relative;
  z-index: 100;
  min-height: 100%;
  height: calc(100vh - 50px);
  text-align: left;

  @media (max-width: $break-md) {
    position: fixed;
    bottom: 0;
    top: auto;
    width: 100%;
    height: 50px;
    left: 0;
    min-height: auto;
    z-index: +100;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  hr {
    margin-top: 12px;
  }
  a {
    text-decoration: none;
    display: block;
    @media (max-width: $break-md) {
      height: 100%;
      line-height: 2;
    }
  }
  .header {
    a.router-link-exact-active.router-link-active {
      li {
        filter: brightness(100%) !important;
        background-color: $bg !important;
      }
      svg {
        fill: $primary !important;
        path {
          fill: $primary !important;
        }
      }
    }
  }
  li {
    white-space: nowrap;
    padding: 8px 20px 8px 20px;
    position: relative;
    cursor: pointer;
    font-size: 13px;
    box-sizing: border-box;
    color: $text-color;
    display: flex;
    align-items: center;
    @media (max-width: $break-md) {
      height: 100%;
      line-height: 2;
      justify-content: center;
    }
    section {
      display: flex;
    }
    svg {
      fill: $text-color;
      width: 20px;
    }
    &:hover {
      background-color: $bg;
      color: $primary;
      svg {
        fill: $primary;
      }
      .sub-menu {
        display: block;
      }
    }
    &.oppened {
      background-color: $bg;
      color: $primary;
      svg {
        fill: $primary;
      }
      .sub-menu {
        display: block;
      }
    }
  }
}
.popover-menu {
  min-width: 180px;
  margin-left: -10px;
  .popover-body {
    padding: 0;
  }
}
.sub-menu {
  background-color: $bg-1;
  list-style: none;
  text-align: left;
  display: flex;
  flex-direction: column;
  @media (max-width: $break-md) {
    animation: fadeInUp 0.6s ease-in-out;
    min-height: calc(100% - 40px);
    bottom: 24px;
    min-width: 100%;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
    section {
      width: 100%;
    }
  }
  svg {
    fill: $text-color;
  }

  a {
    &.router-link-active {
      li {
        * {
          color: $text-color;
          fill: $text-color;
        }
      }
    }
  }
  .close {
    position: absolute;
    right: 0;
  }
  li {
    color: $text-color;
    padding: 4px 12px;
    display: flex;
    align-items: center;
    svg {
      margin-right: 4px;
    }
    &:hover {
      background: $bg-2;
      color: $secondary;
      svg {
        fill: $secondary;
      }
    }
  }
}
</style>
