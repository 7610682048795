import { api } from "@/services/api";
import store from "@/store";

export default {
  state: {
    current_caged: {},
    caged: [],
  },
  getters: {
    current_caged: (state) => state.current_caged,
    caged: (state) => state.caged,
  },
  mutations: {
    current_caged_data(state, current_caged) {
      state.current_caged = current_caged;
    },
    list_caged(state, caged) {
      state.caged = caged;
    },
  },
  actions: {
    get_all_caged({ commit }, params) {
      return new Promise((resolve, reject) => {
        const { is_preview, preview_personify, user } = store.getters;
        let provider;
        if (is_preview) {
          provider = preview_personify.id;
        } else {
          provider = user.enterprise?.id;
        }

        api
          .get(`/provider/${provider}/caged/`, { ...params })
          .then(({ data }) => {
            commit("list_caged");
            resolve({ data });
          })
          .catch((err) => {
            commit("list_caged");
            reject(err);
          });
      });
    },
    edit_caged({ commit }, params) {
      return new Promise((resolve, reject) => {
        const { is_preview, preview_personify, user } = store.getters;
        let provider;
        if (is_preview) {
          provider = preview_personify.id;
        } else {
          provider = user.enterprise?.id;
        }

        api
          .patch(`/provider/${provider}/caged/`, params)
          .then(({ data, status }) => {
            commit("current_caged_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_caged_data");
            reject(err);
          });
      });
    },
  },
};
