export default {
  state: {
    is_dark: false,
  },
  getters: {
    is_dark: (state) => state.is_dark,
  },
  mutations: {
    set_is_dark: (state, payload) => {
      state.is_dark = payload;
    },
  },
  actions: {
    set_dark_mode({ commit }, params) {
      commit("set_is_dark", params);
    },
  },
};
