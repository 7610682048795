/** --- System routes */
export const systemRoutes = {
  path: "/system/",
  meta: {
    breadcrumbs: {
      title: "Sistema",
      label: "Sistema",
      icon: "gear",
      destination: "#",
    },
    transition: "fade",
  },
  component: () => import("../pages/System/"),
  children: [
    {
      path: "/system/template-indicators/list",
      meta: {
        breadcrumbs: {
          title: "Listagem de templates de indicadores",
          label: "Templates",
          icon: "justify",
          destination: "/system/template-indicators/list",
        },
        transition: "fade",
      },
      component: () => import("../pages/System/TemplateIndicators"),
      children: [
        {
          path: "/system/template-indicators/list",
          meta: {
            breadcrumbs: {
              title: "Listagem de Templates de Indicadores",
              label: "Listagem",
              icon: "justify",
              destination: "/system/template-indicators/list",
            },
            transition: "fade",
          },
          component: () => import("../pages/System/TemplateIndicators/List"),
        },
        {
          path: "/system/template-indicators/:id/modules",
          meta: {
            breadcrumbs: {
              title: "Configuração do template de indicadores",
              label: "Módulos",
              icon: "box",
              destination: "#",
            },
            transition: "fade",
          },
          component: () => import("../pages/System/TemplateIndicators/Modules"),
        },
        {
          path: "/system/archives",
          meta: {
            breadcrumbs: {
              title: "Configuração de arquivos",
              label: "Arquivos",
              icon: "file",
              destination: "#",
            },
            transition: "fade",
          },
          component: () => import("../pages/System/Archives/"),
        },
      ],
    },
    {
      path: "/system/apperence/",
      meta: {
        breadcrumbs: {
          title: "Aparência do Sistema ",
          label: "Aparência",
          icon: "brush",
          destination: "/system/apperence/",
        },
        transition: "fade",
      },
      component: () => import("../pages/System/Apparence"),
    },
    {
      path: "/system/permissions/",
      meta: {
        breadcrumbs: {
          title: "Permissões do Sistema ",
          label: "Permissões",
          icon: "brush",
          destination: "/system/permissions/",
        },
        transition: "fade",
      },
      component: () => import("../pages/System/Permissions"),
    },
    {
      path: "/system/questions",
      meta: {
        breadcrumbs: {
          title: "Configuração de questionário",
          label: "Questionários",
          icon: "question-circle",
          destination: "/system/questions/list",
        },
        transition: "fade",
      },
      component: () => import("../pages/System/Questions/"),
      children: [
        {
          path: "/system/questions/list",
          meta: {
            breadcrumbs: {
              title: "Configuração de questionário",
              label: "Questionários",
              icon: "justify",
              destination: "/system/questions/list",
            },
            transition: "fade",
          },
          component: () => import("../pages/System/Questions/List"),
        },
        {
          name: 'pages/System/Questions',
          path: "/system/questions/:id",
          props: true,
          meta: {
            breadcrumbs: {
              title: "Configuração dos questionários",
              label: "Questionários",
              icon: "box",
              destination: "#",
            },
            transition: "fade",
          },
          component: () => import("../pages/System/Questions/Questions"),
        },
      ]
    },
    {
      path: "/system/documents",
      meta: {
        breadcrumbs: {
          title: "Configuração de Certidões, Certificações, Alvarás e outros",
          label: "Certidões, Certificações, Alvarás e outros",
          icon: "file-text",
          destination: "/system/documents/list",
        },
        transition: "fade",
      },
      component: () => import("../pages/System/Documents/"),
      children: [
        {
          path: "/system/documents/list",
          meta: {
            breadcrumbs: {
              title: "Configuração de Certidões, Certificações, Alvarás e outros",
              label: "Certidões, Certificações, Alvarás e outros",
              icon: "justify",
              destination: "/system/documents/list",
            },
            transition: "fade",
          },
          component: () => import("../pages/System/Documents/List"),
        },
        {
          name: 'pages/System/Documents',
          path: "/system/documents/:id",
          props: true,
          meta: {
            breadcrumbs: {
              title: "Configuração dos Certidões, Certificações, Alvarás e outros",
              label: "Certidões, Certificações, Alvarás e outros",
              icon: "box",
              destination: "#",
            },
            transition: "fade",
          },
          component: () => import("../pages/System/Documents/Documents"),
        },
      ]
    },
    {
      path: "/system/logs",
      meta: {
        breadcrumbs: {
          title: "Logs de Auditoria",
          label: "Logs",
          icon: "file-earmark-text",
          destination: "/system/logs/filter",
        },
        transition: "fade",
      },
      component: () => import("../pages/System/Logs/"),
      children: [
        {
          path: "/system/logs/filter",
          meta: {
            breadcrumbs: {
              title: "Logs de Auditoria",
              label: "Logs",
              icon: "justify",
              destination: "/system/logs/filter",
            },
            transition: "fade",
          },
          component: () => import("../pages/System/Logs/Filter"),
        },
      ]
    },
    {
      path: "/system/satisfaction-survey",
      meta: {
        breadcrumbs: {
          title: "Pesquisa de satisfação",
          label: "Pesquisa de satisfação",
          icon: "chat-quote ",
          destination: "/system/satisfaction-survey/list",
        },
        transition: "fade",
      },
      component: () => import("../pages/System/SatisfactionSurvey/"),
      children: [
        {
          path: "/system/satisfaction-survey/list",
          meta: {
            breadcrumbs: {
              title: "Listagem de Pesquisas de Satisfação",
              label: "Questionários",
              icon: "justify",
              destination: "/system/satisfaction-survey/list",
            },
            transition: "fade",
          },
          component: () => import("../pages/System/SatisfactionSurvey/list"),
        },
        {
          path: "/system/satisfaction-survey/questions/:id",
          meta: {
            breadcrumbs: {
              title: "Editar",
              label: "Editar",
              icon: "pencil",
              destination: "#",
            },
            transition: "fade",
          },
          component: () =>
            import("../pages/System/SatisfactionSurvey/questions"),
        },
      ],
    },
  ],
};
