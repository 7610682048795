<template>
  <footer class="page-footer font-small application-footer">
    <!-- Copyright -->
    <div class="footer-copyright text-center py-3">
      Maat © {{new Date().getFullYear()}} | Todos os direitos reservados
    </div>
    <!-- Copyright -->
  </footer>
</template>
<script>
export default {
  name: "Footer",
};
</script>
<style scoped>
.application-footer {
  position: absolute;
  width: 100%;
  font-size: 14px;
}
</style>