<template>
  <div>
    <div
      class="d-flex justify-content-between align-items-end mt-2 mt-md-0"
      v-if="crumbs[0] && !crumbs[0].hidden"
    >
      <div>
        <div class="d-flex align-items-center mt-md-4 my-2">
          <b-icon
            v-if="hasBackLink(crumbs)"
            @click="backButton(crumbs)"
            icon="arrow-left"
            class="mr-2 pointer h6 mb-0"
          />
          <h5 v-if="crumbs.length" class="mb-0">
            {{ crumbs[crumbs.length - 1].title }}
          </h5>
        </div>
        <b-breadcrumb active-color="primary" class="py-1 py-md-2 pl-0 mb-0">
          <small class="d-flex align-items-center flex-wrap">
            <router-link
              :to="crumb.destination"
              v-for="(crumb, index) in crumbs"
              :key="index"
            >
              <b-icon icon="chevron-right" v-if="index > 0" />
              <b-icon :icon="crumb.icon" variant="primary" class="mx-1" />
              <span>{{ crumb.label }}</span>
            </router-link>
          </small>
        </b-breadcrumb>
      </div>

      <!-- <div class="d-flex align-items-center">
        <b-button
          variant="link"
          class="mb-0 p-0"
          v-b-modal.report-bug
          v-b-popover.hover.top="'Reportar um erro nessa página'"
        >
          <b-icon icon="bug"> </b-icon>
        </b-button>
      </div> -->
    </div>
    <hr class="mt-0 no-border" />
    <b-modal id="report-bug" centered size="md">
      <template #modal-header> <h5>Reportar um erro encontrado</h5></template>
      <p>Descreva o que aconteceu?</p>
      <b-form-textarea v-model="report"> </b-form-textarea>
      <template #modal-footer="{ close }">
        <b-button variant="link" @click="close()">Cancelar</b-button>
        <b-button variant="primary" @click="handleReport()">Enviar</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { sendReport } from "@/report/report";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      report: "",
      homologation_id: "",
      service_id: "",
      user_role: "",
      crumbs: [],
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    replaceBreadcrumbsParams(breadcrumbs) {
      const labels = breadcrumbs.flatMap(({ label }) => [label]);
      const titleBrowser = document.querySelector("title");

      titleBrowser.text = ` ${labels.at(-1) || "Sistema"} - Maat Gestão `;

      breadcrumbs = breadcrumbs.map((bread) => {
        if (bread.destination) {
          bread.destination = bread.destination
            .replaceAll(":homologation_id", this.homologation_id)
            .replaceAll(":service_id", this.service_id)
            .replaceAll(":user_role", this.user.role);

          return bread;
        }
        return bread;
      });
      this.crumbs = breadcrumbs;
    },

    hasBackLink(crumbs) {
      return crumbs.length && crumbs[crumbs.length - 1].destination === "#";
    },

    backButton() {
      this.$router.go(-1);
    },

    handleReport() {
      const pageTitle = `FRONT | PÁGINA | ${this.$route.meta.breadcrumbs.title}`;
      var url = `${window.location.origin}#${this.$route.path}`;
      sendReport(pageTitle, url, this.report).then(() => {
        this.report = "";
        this.$bvModal.hide("report-bug");
      });
    },
  },

  mounted() {
    this.service_id = this.$route.params.service_id;

    this.homologation_id = this.$route.params.homologation_id;

    this.user_role = this.user.role;
  },

  watch: {
    "breadcrumbs$.breadcrumbs": {
      handler(breadcrumbs) {
        if (breadcrumbs.length > 0) {
          this.replaceBreadcrumbsParams(breadcrumbs);
        }
      },
      deep: true,
      immediate: true,
    },

    "$route.params": {
      handler(params) {
        const serviceId = params.service_id;

        if (serviceId && this.crumbs.length > 0) {
          this.replaceBreadcrumbsParams(this.crumbs);
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
