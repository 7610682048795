import { api } from "@/services/api";
import store from "@/store";

export default {
  state: {
    current_payments: {},
    payments: [],
  },
  getters: {
    current_payments: (state) => state.current_payments,
    payments: (state) => state.payments,
  },
  mutations: {
    current_payments_data(state, current_payments) {
      state.current_payments = current_payments;
    },
    list_payments(state, payments) {
      state.payments = payments;
    },
  },
  actions: {
    get_all_payments({ commit }, params) {
      return new Promise((resolve, reject) => {
        const { is_preview, preview_personify, user } = store.getters;
        let provider;
        if (is_preview) {
          provider = preview_personify.id;
        } else {
          provider = user.enterprise?.id;
        }

        api
          .get(`/provider/${provider}/payment/`, { ...params })
          .then(({ data, status }) => {
            commit("list_payments");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("list_payments");
            reject(err);
          });
      });
    },
    edit_payments({ commit }, params) {
      return new Promise((resolve, reject) => {
        const { is_preview, preview_personify, user } = store.getters;
        let provider;
        if (is_preview) {
          provider = preview_personify.id;
        } else {
          provider = user.enterprise?.id;
        }

        api
          .patch(`/provider/${provider}/payment/`, {
            ...params,
          })
          .then(({ data, status }) => {
            commit("current_payments_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_payments_data");
            reject(err);
          });
      });
    },
  },
};
