const formatMoney = (value) => {
  if (typeof value !== "number") {
    parseInt(value);
  }
  const formatter = new Intl.NumberFormat([], {
    style: "currency",
    currency: "BLR",
  });
  return formatter.format(value).replace("BLR", "R$");
};
export { formatMoney };
