import { api } from "@/services/api";
import store from "@/store";

export default {
  state: {
    current_insurance_provider: {},
    insurance_providers: [],
    insurance_type: [],
  },
  getters: {
    current_insurance_provider: (state) => state.current_insurance_provider,
    insurance_providers: (state) => state.insurance_providers,
  },
  mutations: {
    current_insurance_provider_data(state, current_insurance_provider) {
      state.current_insurance_provider = current_insurance_provider;
    },
    list_insurance_providers(state, insurance_providers) {
      state.insurance_providers = insurance_providers;
    },
  },
  actions: {
    get_all_insurance_providers({ commit }, params) {
      return new Promise((resolve, reject) => {
        const { is_preview, preview_personify, user } = store.getters;

        let provider;
        if (is_preview) {
          provider = preview_personify.id;
        } else {
          provider = user.enterprise?.id;
        }

        api
          .get("/insurance-provider/", { ...params, provider })
          .then(({ data, status }) => {
            commit("list_insurance_providers");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("list_insurance_providers");
            reject(err);
          });
      });
    },
    get_insurance_provider({ commit }, params) {
      return new Promise((resolve, reject) => {
        let id;
        const { is_preview, preview_personify } = store.getters;

        if (is_preview) {
          params.provider = preview_personify.id;
        }
        id = params.id;
        delete params.id;

        api
          .get(`/insurance-provider/${id}/`, {
            ...params,
          })
          .then(({ data, status }) => {
            commit("current_insurance_provider_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_insurance_provider_data");
            reject(err);
          });
      });
    },
    edit_insurance_provider({ commit }, params) {
      return new Promise((resolve, reject) => {
        let id;
        const { is_preview, preview_personify } = store.getters;

        if (is_preview) {
          params.provider = preview_personify.id;
        }
        id = params.id;
        delete params.id;

        api
          .patch(`/insurance-provider/${id}/`, {
            ...params,
          })
          .then(({ data, status }) => {
            commit("current_insurance_provider_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_insurance_provider_data");
            reject(err);
          });
      });
    },
    add_insurance_provider({ commit }, params) {
      return new Promise((resolve, reject) => {
        const { is_preview, preview_personify } = store.getters;

        if (is_preview) {
          params.provider = preview_personify.id;
        } else {
          delete params.id;
        }

        api
          .post("/insurance-provider/", { ...params })
          .then(({ data }) => {
            commit("current_insurance_provider_data");
            resolve(data);
          })
          .catch((err) => {
            commit("current_insurance_provider_data");
            reject(err);
          });
      });
    },
    delete_insurance_provider({ commit }, params) {
      return new Promise((resolve, reject) => {
        const id = params.id;
        delete params.id;

        api
          .delete(`/insurance-provider/${id}/`)
          .then(({ data }) => {
            commit("list_insurance_providers");
            resolve(data);
          })
          .catch((err) => {
            commit("list_insurance_providers");
            reject(err);
          });
      });
    },
    
    get_all_type({ commit }, params) {
      return new Promise((resolve, reject) => {        
        api
        .get("/insurance-type/", { ...params }) 
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
    
    
    /*
    get_all_type({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get("/insurance-type/", { ...params })
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  */


};
