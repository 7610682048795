<template>
  <div class="text-center empty-list w-100" :style="`height: ${size || 50}vh`">
    <div>
      <img
        class="mb-4"
        src="@/assets/images/empty-box.png"
        alt=""
        height="60"
      />
      <p>{{ text || "Não há dados para serem exibidos" }}</p>
      <slot />
    </div>
  </div>
</template>
<style scoped lang="scss">
.empty-list {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<script>
export default {
  props: {
    text: {
      type: String,
    },
    size: {
      type: [String, Number],
    },
  },
};
</script>
