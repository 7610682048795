import { api } from "@/services/api";
import store from "@/store";

export default {
  state: {
    current_sesmets: {},
    sesmets: [],
  },
  getters: {
    current_sesmets: (state) => state.current_sesmets,
    sesmets: (state) => state.sesmets,
  },
  mutations: {
    current_sesmets_data(state, current_sesmets) {
      state.current_sesmets = current_sesmets;
    },
    list_sesmets(state, sesmets) {
      state.sesmets = sesmets;
    },
  },
  actions: {
    get_all_sesmets({ commit }, params) {
      return new Promise((resolve, reject) => {
        const { is_preview, preview_personify, user } = store.getters;
        let provider;
        if (is_preview) {
          provider = preview_personify.id;
        } else {
          provider = user.enterprise?.id;
        }

        api
          .get(`/provider/${provider}/sesmet/`, { ...params })
          .then(({ data }) => {
            commit("list_sesmets");
            resolve(data);
          })
          .catch((err) => {
            commit("list_sesmets");
            reject(err);
          });
      });
    },
    edit_sesmets({ commit }, params) {
      return new Promise((resolve, reject) => {
        const { is_preview, preview_personify, user } = store.getters;
        let provider;
        if (is_preview) {
          provider = preview_personify.id;
        } else {
          provider = user.enterprise?.id;
        }

        api
          .patch(`/provider/${provider}/sesmet/`, {
            ...params,
          })
          .then(({ data, status }) => {
            commit("current_sesmets_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_sesmets_data");
            reject(err);
          });
      });
    },
  },
};
