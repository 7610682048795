const datePickerCustomConfig = {
    nextMonthCaption: 'Próximo Mês',
    prevMonthCaption: 'Mês Anterior',
    setTimeCaption: 'Horário:',
    weekdays: ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom'],
    months: [
      'Janeiro', 'Fevereiro', 'Março', 'Abril',
      'Maio', 'Junho', 'Julho', 'Agosto',
      'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ]
  };

  export default datePickerCustomConfig;