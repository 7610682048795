import { api } from "@/services/api";

export default {
  state: {
    segment: {},
    segments: [],
  },
  getters: {
    segment: (state) => state.segment,
    segments: (state) => state.segments,
  },
  mutations: {
    segment_data(state, segment) {
      state.segment = segment;
    },
    list_segments(state, segments) {
      state.segments = segments;
    },
  },
  actions: {
    get_all_segment({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get("/segment/", { ...params })
          .then(({ data }) => {
            commit("list_segments");
            resolve(data);
          })
          .catch((err) => {
            commit("list_segments");
            reject(err);
          });
      });
    },
    get_segment({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get(`/segment/${params.id}/`, { ...params })
          .then(({ data }) => {
            commit("segment_data");
            resolve(data);
          })
          .catch((err) => {
            commit("segment_data");
            reject(err);
          });
      });
    },
    edit_segment({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .patch(`/segment/${params.id}/`, { ...params })
          .then(({ data }) => {
            commit("segment_data");
            resolve(data);
          })
          .catch((err) => {
            commit("segment_data");
            reject(err);
          });
      });
    },
    add_segment({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .post("/segment/", { ...params })
          .then(({ data }) => {
            commit("segment_data");
            resolve(data);
          })
          .catch((err) => {
            commit("segment_data");
            reject(err);
          });
      });
    },
    delete_segment({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .delete(`/segment/${id}/`)
          .then(({ data }) => {
            commit("list_segments");
            resolve(data);
          })
          .catch((err) => {
            commit("list_segments");
            reject(err);
          });
      });
    },
  },
};
