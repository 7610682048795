export default {
  state: {
    toastMessages: null,
    in_loading: false,
    message_loading: "",
    in_delete: false,
    message_delete: "",
  },
  getters: {
    toastMessages: (state) => state.toastMessages,
    in_loading: (state) => state.in_loading,
    message_loading: (state) => state.message_loading,
    in_delete: (state) => state.in_delete,
    message_delete: (state) => state.message_delete,
  },
  mutations: {
    add_toast_message: (state, payload) => {
      const { message, type } = payload;
      state.toastMessages = { ...state.toastMessages, message, type };
    },
    toggle_loading: (state, { message, loading }) => {
      state.in_loading = loading;
      state.message_loading = message || "Carregando dados";
    },
    toggle_delete: (state, { message, in_delete }) => {
      state.in_delete = in_delete;
      state.message_delete = message || "";
    },
  },
  actions: {
    show_toast({ commit }, params) {
      commit("add_toast_message", params);
    },
    show_loading({ commit }, params) {
      commit("toggle_loading", params);
    },
    show_delete({ commit }, params) {
      commit("toggle_delete", params);
    },
  },
};
