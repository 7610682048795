import axios from "axios";
import { api } from "@/services/api";
import store from "@/store";

export default {
  state: {
    status: "",
    token: localStorage.getItem("token") || "",
    uuid: localStorage.getItem("uuid"),
  },
  getters: {
    isLoggedIn: (state) => {
      return !!state.token && !!state.uuid;
    },
    authStatus: (state) => state.status,
    token: (state) => state.token,
    uuid: (state) => state.uuid,
  },
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, { token, user }) {
      state.status = "success";
      state.token = token;
      state.uuid = user.id;
      state.user = user;
      store.dispatch("update_user", user);
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      return new Promise((resolve) => {
        state.status = "";
        state.token = "";
        localStorage.removeItem("uuid");
        localStorage.removeItem("token");
        localStorage.removeItem("personify");
        localStorage.removeItem("ispvd");

        return resolve();
      });
    },
  },
  actions: {
    accept({ commit }, { key }) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        try {
          api.post(`/notification/invite/${key}/accept/`).then(({ data }) => {
            localStorage.setItem("token", data.token);
            localStorage.setItem("uuid", data.user.id);
            axios.defaults.headers.common["Authorization"] = data.token;
            const { token, user } = data;
            commit("auth_success", { token, user });
            resolve({ data, status });
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        try {
          api
            .post("auth/", { ...user })
            .then(({ data, status }) => {
              if (data?.token) {
                localStorage.setItem("token", data.token);
                localStorage.setItem("uuid", data.user.id);
                axios.defaults.headers.common["Authorization"] = data.token;
                const { token, user } = data;
                commit("auth_success", { token, user });
                resolve({ data, status });
              } else {
                reject({ data, status });
              }
            })
            .catch((err) => {
              commit("auth_error");
              localStorage.removeItem("token");
              reject(err);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit("logout");
        localStorage.removeItem("token");
        delete axios.defaults.headers.common["Authorization"];
        resolve();
      });
    },
    validate_key({ commit }, key) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        api
          .get(`/notification/invite/${key}/`)
          .then(({ data }) => {
            const { token, user } = data;
            if (token) {
              localStorage.setItem("token", data.token);
              axios.defaults.headers.common["Authorization"] = data.token;
              commit("auth_success", { token, user });
              resolve(data);
            }
          })
          .catch((err) => {
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    recover({ commit }, email) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        try {
          api
            .post("/user/recover/", { ...email })
            .then(({ data, status }) => {
              resolve({ data, status });
            })
            .catch((err) => {
              commit("auth_error");
              localStorage.removeItem("token");
              reject(err);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
