import Avatar from "@/components/Avatar";
import CardsList from "@/components/CardsList";
import ContextMenu from "@/components/ContextMenu";
import EmptyList from "@/components/Empty";
import Skeleton from "@/components/Skeleton";
/** Firebase */
import "@/configs/firebase";
/** Firebase */
import "@/directives/disable";
import "@/directives/permission";
import { formatMoney } from "@/helpers/formatMoney";
import { router } from "@/routes";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import BreadcrumbsModule from "@oarepo/vue-breadcrumbs";
// import * as Sentry from "@sentry/capacitor";
import "animate.css";
import Axios from "axios";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
import "croppie/croppie.css";
import moment from "moment";
import "moment/locale/pt-br";
import "nouislider/dist/nouislider.css";
import { VueMaskDirective, VueMaskFilter } from "v-mask";
import money from "v-money";
import VSwitch from "v-switch-case";
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import VueCroppie from "vue-croppie";
import Multiselect from "vue-multiselect";
import NProgress from "vue-nprogress";
import VuePageTransition from "vue-page-transition";
import Unicon from "vue-unicons";
import * as icon from "vue-unicons/src/icons";
import { uniLayerGroupMonochrome } from "vue-unicons/src/icons";
import * as VueGoogleMaps from "vue2-google-maps";
import Vuex from "vuex";
import i18n from "../i18n";
import App from "./App.vue";
import "./configs/firebase";
import layoutProvider from "./services/apparence/layout";
import store from "./store";

import "vue-multiselect/dist/vue-multiselect.min.css";
import "./styles/style.scss";

import themeProvider from "./services/apparence/theme";

// Multiselect Dual List
import vMultiselectListbox from 'vue-multiselect-listbox'

// vue-date-pick
import DatePick from 'vue-date-pick';
import datePickerCustomConfig from './constants/DatePickerConfigPlugin';
import 'vue-date-pick/dist/vueDatePick.css';

moment.locale("pt-br");
themeProvider(localStorage.getItem("theme") ?? "light");
layoutProvider(localStorage.getItem("layout") ?? "boxed");

/**
 * ---------------------------
 * Directives
 */

Vue.use(NProgress);
Vue.directive("init", {
  bind: function(el, binding, vnode) {
    vnode.context[binding.expression]();
  },
});
Vue.use(VSwitch);

/**
 * ---------------------------
 * Filters
 */
Vue.filter("VMask", VueMaskFilter);
Vue.filter("date", (value) => moment(String(value)).format("DD/MM/YYYY"));
Vue.filter("dateFull", (value) =>
  moment(String(value)).format("DD/MM/YYYY HH:mm")
);
Vue.filter(
  "capitalize",
  (value) => value?.charAt(0).toUpperCase() + value?.slice(1)
);
Vue.filter("number", (value) => {
  if (!value) return 0;

  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
});

Vue.filter("money", formatMoney);

Vue.directive("mask", VueMaskDirective);
Vue.prototype.$http = Axios;
Vue.prototype.moment = moment;
Vue.prototype.$datePickerConfig = datePickerCustomConfig;

/**
 * ---------------------------
 * Providers
 */
Vue.use(VuePageTransition);
Vue.use(Vuex);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.use(BreadcrumbsModule, { store });

Vue.use(money, { precision: 4 });

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyC2OzcmjF2o43N652xP-6Evf8x8shoGW88", // #conta vinculada Jayme, necessario alterar para .env e add a key da maat.
    libraries: "geometry,drawing,places", // This is required if you use the Autocomplete plugin
  },
});

Vue.use(VueCroppie);

/**
 * ---------------------------
 * Object icons unicon
 */
const objIcons = { ...icon };
const allIcons = [];
Object.keys(objIcons).map((icon) => allIcons.push(objIcons[icon]));
Unicon.add([uniLayerGroupMonochrome, ...allIcons]);
Vue.use(Unicon, { name: "icon" });

/**
 * ---------------------------
 * Components
 */
Vue.component("chart", VueApexCharts);
Vue.component("skeleton", Skeleton);
Vue.component("cards-list", CardsList);
Vue.component("empty-list", EmptyList);
Vue.component("context-menu", ContextMenu);
Vue.component("avatar", Avatar);
Vue.component("multiselect", Multiselect);
Vue.component('v-multiselect-listbox', vMultiselectListbox)
Vue.component('date-pick', DatePick)

const mockStorage = localStorage.getItem("mock");

const turnOnMock = mockStorage === "true";

if (process.env.NODE_ENV === "development" && turnOnMock) {
  const { worker } = require("./mocks/browser");
  worker.start();
}

Vue.config.productionTip = false;

const nprogress = new NProgress();

new Vue({
  nprogress,
  render: (h) => h(App),
  i18n,
  router,
  store,
}).$mount("#app");

defineCustomElements(window);
