export default {
  state: {
    error_message: "",
    error_status: null,
  },
  getters: {
    error_message: (state) => state.error_message,
    error_status: (state) => state.error_status,
  },
  mutations: {
    add_error_status: (state, payload) => {
      const { message, status } = payload;
      state.error_message = message;
      state.error_status = status;
    },
  },
  actions: {
    send_error({ commit }, params) {
      commit("add_error_status", params);
    },
  },
};
