import { api } from "@/services/api";

export default {
  state: {
    type_action: [],
  },
  getters: {
    type_action: (state) => state.type_action,
  },
  mutations: {
    set_type_action: (state, payload) => {
      state.type_action = payload;
    },
  },
  actions: {
    get_all_type_action({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .get(`/type-action/`)
          .then(({ data }) => {
            commit("set_type_action", data);
            resolve({ data });
          })
          .catch((err) => {
            commit("set_type_action", err);
            reject(err);
          });
      });
    },
    get_logs({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get("/log/", { ...params })
          .then(({ data, status }) => {
            resolve({ data, status });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
