import { api } from "@/services/api";

export default {
  state: {
    current_template_indicator: {},
    template_indicators: [],
  },
  getters: {
    current_template_indicator: (state) => state.current_template_indicator,
    template_indicators: (state) => state.template_indicators,
  },
  mutations: {
    current_template_indicator_data(state, current_template_indicator) {
      state.current_template_indicator = current_template_indicator;
    },
    list_template_indicators(state, template_indicators) {
      state.template_indicators = template_indicators;
    },
  },
  actions: {
    get_all_template_indicator({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .get("/template-indicator/", { ...params })
          .then(({ data }) => {
            commit("list_template_indicators", data);
            resolve(data);
          })
          .catch((err) => {
            commit("list_template_indicators");
            reject(err);
          });
      });
    },
    get_template_indicator({ commit }, params) {
      const id = params.id;
      delete params.id;
      return new Promise((resolve, reject) => {
        api
          .get(`/template-indicator/${id}/`, { ...params })
          .then(({ data, status }) => {
            commit("current_template_indicator_data", data);
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_template_indicator_data");
            reject(err);
          });
      });
    },
    edit_template_indicator({ commit }, params) {
      const id = params.id;
      delete params.id;
      return new Promise((resolve, reject) => {
        api
          .patch(`/template-indicator/${id}/`, { ...params })
          .then(({ data, status }) => {
            commit("current_template_indicator_data", data);
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_template_indicator_data");
            reject(err);
          });
      });
    },
    add_template_indicator({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .post("/template-indicator/", { ...params })
          .then(({ data, status }) => {
            commit("current_template_indicator_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_template_indicator_data");
            reject(err);
          });
      });
    },

    
    delete_template_indicator({ commit }, id) {
      return new Promise((resolve, reject) => {
        api
          .delete(`/template-indicator/${id}/`)
          .then(({ data }) => {
            commit("list_template_indicators");
            resolve(data);
          })
          .catch((err) => {
            commit("list_template_indicators");
            reject(err);
          });
      });
    },
  },
};
