import { api } from "@/services/api";
import store from "@/store";

export default {
  state: {
    current_system_management: {},
    system_management: [],
  },
  getters: {
    current_system_management: (state) => state.current_system_management,
    system_management: (state) => state.system_management,
  },
  mutations: {
    current_system_management_data(state, current_system_management) {
      state.current_system_management = current_system_management;
    },
    list_system_management(state, system_management) {
      state.system_management = system_management;
    },
  },
  actions: {
    get_all_system_management({ commit }, params) {
      return new Promise((resolve, reject) => {
        const { is_preview, preview_personify, user } = store.getters;
        let provider;
        if (is_preview) {
          provider = preview_personify.id;
        } else {
          provider = user?.enterprise?.id;
        }

        api
          .get(`/provider/${provider}/system-management/`, { ...params })
          .then(({ data }) => {
            commit("list_system_management");
            resolve(data);
          })
          .catch((err) => {
            commit("list_system_management");
            reject(err);
          });
      });
    },
    edit_system_management({ commit }, params) {
      return new Promise((resolve, reject) => {
        const { is_preview, preview_personify, user } = store.getters;
        let provider;
        if (is_preview) {
          provider = preview_personify.id;
        } else {
          provider = user.enterprise?.id;
        }
        const id = params.id;
        delete params.id;
        api
          .patch(`/provider/${provider}/system-management/${id}/`, {
            ...params,
          })
          .then(({ data, status }) => {
            commit("current_system_management_data");
            resolve({ data, status });
          })
          .catch((err) => {
            commit("current_system_management_data");
            reject(err);
          });
      });
    },
  },
};
