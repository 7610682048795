/** --- Administrators users routes */
export const usersRoutes = {
  path: "/users/list",
  meta: {
    breadcrumbs: {
      title: "Editar usuários",
      label: "Usuários",
      icon: "person",
      destination: "/users/list",
    },
    transition: "fade",
  },
  component: () => import("../pages/Users/"),
  children: [
    {
      path: "/users/list",
      meta: {
        breadcrumbs: {
          title: "Listagem de Usuários",
          label: "Listagem de Usuários",
          icon: "justify",
          destination: "/users/list",
        },
        transition: "fade",
      },
      component: () => import("../pages/Users/list"),
    },
    {
      path: "/users/detail/:id",
      meta: {
        breadcrumbs: {
          title: "Detalhes do Usuário",
          label: "Detalhes",
          icon: "person",
          destination: "#",
        },
        transition: "fade",
      },
      component: () => import("../pages/Users/detail"),
    },
  ],
};
