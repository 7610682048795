<template>
  <b-modal id="modal-confirm-delete" centered size="sm">
    <template #modal-header> <h5>Cuidado!</h5></template>
    <p>
      {{
        message_delete
          ? message_delete
          : `Você apagará o registro selecionado. Essa ação não poderá ser desfeita, deseja continuar?`
      }}
    </p>
    <template #modal-footer="{ close }">
      <b-button variant="link" size="sm" @click="handleDeleteItem()">
        Sim
      </b-button>
      <b-button variant="primary" size="sm" @click="close()">Não</b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["in_delete", "message_delete"]),
  },
  methods: {
    ...mapActions(["show_delete"]),
    handleDeleteItem() {
      this.$root.$emit("confirm-close-delete", true);
    },
  },
  created() {
    this.$root.$on("bv::modal::hidden", (bevent, id) => {
      if (id === "modal-confirm-delete") {
        this.show_delete({
          in_delete: false,
        });
      }
    });
  },
  watch: {
    in_delete: {
      handler(value) {
        if (value) {
          this.$bvModal.show("modal-confirm-delete");
        } else {
          this.$bvModal.hide("modal-confirm-delete");
        }
      },
      immediate: true,
    },
  },
};
</script>
