<template>
  <div></div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      count: 0,
      titles: {
        error: "OPS!",
        success: "Sucesso!",
        warning: "Cuidado!",
        info: "Sistema",
      },
    };
  },
  computed: {
    ...mapGetters(["toastMessages"]),
  },
  watch: {
    toastMessages: {
      handler(data) {
        const { message, type, autoHideDelay } = data;
        const h = this.$createElement;
        const id = `toast-${this.count++}`;

        const vNodesMsg = h(
          "div",
          { class: ["d-flex", "mb-0", "align-items-center", "w-100"] },
          [
            h(
              "span",
              { class: "material-icons icon-status" },
              type === "error" ? "close" : "check"
            ),
            h("section", { class: ["d-block w-100"] }, [
              h("strong", { class: "d-block title" }, this.titles[type]),
              h("span", { class: "d-block content-toast" }, message),
            ]),
            h(
              "span",
              {
                on: { click: () => this.$bvToast.hide(id) },
                class: "material-icons icon-close icon-action",
              },
              "close"
            ),
          ]
        );
        this.$bvToast.toast([vNodesMsg], {
          variant: type,
          id: id,
          autoHideDelay: autoHideDelay || 3000,
          appendToast: true,
          noCloseButton: true,
        });
      },
      deep: true,
    },
  },
};
</script>

 